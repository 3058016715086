import React, {useCallback, useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import DeviceDescriptionCardView from "./device_description_card/DeviceDescriptionCardView";
import DetailCardView from "./device_detail_card/DetailCardView";
import SensorDataCardView from "./sensor_data_card/SensorDataCardView";
import RdThresholdCardView from "./rd_threshold_card/RdThresholdCardView";
import clsx from "clsx";
import AQICalendarCardView from "./calendar_card/AQICalendarCardView";
import NoiseCardView from "./noise_card/NoiseCardView";
import SensorStatisticCardView from "./sensor_statistic_card/SensorStatisticCardView";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {updateSelectedStationAction} from "../../../../reducers/dashboardUIReducer";
import DeviceNotFound from "./DeviceNotFound";
import WindSpeedCardView from "./wind_speed_card/WindSpeedCardView";
import VocIaqCardView from "./voc_iaq_card/VocIaqCardView";
import ScatterPlotCardView from "./scatter_plot_card/ScatterPlotCardView";
import TemporalVariationCardView from "./temporal_variation_card/TemporalVariationCardView";
import PollutantRoseCardView from "./pollutant_rose/PollutantRoseCardView";
import {MapContextProvider} from "./pollutant_rose/map_layer/MapContext";
import VerifyingDevice from "./VerifyingDevice";
import {getStationSensors} from "../../../../utils/stationUtil";

const useStyles = makeStyles({
    root: {
        maxWidth: 1800,
        paddingBottom: 20,
        paddingTop: 20,
        paddingLeft: 40,
        paddingRight: 40,
    },
    card: {
        background: "white",
        padding: 20,
        borderRadius: 5,
        minHeight: 245
    },
    firstRowCard: {
        height: 250
    },
    sensorDataRowCard: {
        height: 760
    },
    scatterPlotRowCard: {
        height: 480
    },
    vocDataRowCard: {
        height: 480
    },
    tempVariation: {
        height: 950
    },
    noiseDataRowCard: {
        height: 580
    },
    statisticRowCard: {
        minHeight: 300
    },
    thirdRowCard: {
        height: 460
    },
    windSpeedCard: {
        height: 660
    },
    fourthRowCard: {
        height: 460
    },
    notFoundCard: {
        height: 600
    },
    loading: {
        height: 600
    },
    trendCard: {
        paddingLeft: 0,
        paddingRight: 0
    },
    pollutantRoseCard:{
        height: 700
    }
});

const AnalyticScreen = ({location}) => {

    const dispatch = useDispatch();
    const [loading,setLoading] = useState(true);
    const [isSensorIdValid, setIsSensorIdValid] = useState(false);
    const stations = useSelector(state => state.dashboardUI.stations);
    const selectedStation = useSelector(state => state.dashboardUI.selectedStation);
    const selectedStationSensors = useSelector(state => (
        getStationSensors(state.dashboardUI.stations?.find(item => item.id === selectedStation))
    ), shallowEqual);

    useEffect(() => {
            checkStationId(location);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [location]);

    const checkStationId = useCallback((location) => {
        let stationId = location.pathname.split("/")[2];

        const stationFound = stations.find(item => item.id === stationId);

        if (stationFound) {
            localStorage.setItem('lastStation', stationId);
            dispatch(updateSelectedStationAction(stationId));
        }else{
            dispatch(updateSelectedStationAction(""));
        }
        setIsSensorIdValid(stationFound !== undefined);
        setLoading(false);

    }, [stations, dispatch]);

    const classes = useStyles();

    return (
        <Grid className={classes.root} container direction={"row"}
              alignItems={"center"} justifyContent="space-around" spacing={2}>
            {(isSensorIdValid && !loading) &&
            <>
                <Grid item xs={12} md={12} lg={6}>
                    <DeviceDescriptionCardView className={clsx(classes.card, classes.firstRowCard)}/>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <DetailCardView className={clsx(classes.card, classes.firstRowCard)}/>
                </Grid>
                <Grid item xs={12}>
                    <SensorDataCardView className={clsx(classes.card, classes.sensorDataRowCard)} boxShadow={2}/>
                </Grid>
                <Grid item xs={12}>
                    <ScatterPlotCardView className={clsx(classes.card, classes.scatterPlotRowCard)} boxShadow={2}/>
                </Grid>
                {selectedStationSensors?.includes("VOC_IAQ") &&
                    <Grid item xs={12}>
                        <VocIaqCardView className={clsx(classes.card, classes.vocDataRowCard)} boxShadow={2}/>
                    </Grid>
                }
                <Grid item xs={12}>
                    <SensorStatisticCardView className={clsx(classes.card, classes.statisticRowCard)} boxShadow={2}/>
                </Grid>
                {selectedStationSensors?.includes("noise") &&
                    <Grid item xs={12}>
                        <NoiseCardView className={clsx(classes.card, classes.noiseDataRowCard)} boxShadow={2}/>
                    </Grid>
                }
                <Grid item xs={12}>
                    <TemporalVariationCardView className={clsx(classes.card, classes.tempVariation)} boxShadow={2}/>
                </Grid>
                {selectedStationSensors?.includes("wind") &&
                    <Grid item xs={12}>
                        <WindSpeedCardView className={clsx(classes.card, classes.windSpeedCard)} boxShadow={2}/>
                    </Grid>
                }
                {selectedStationSensors?.includes("wind") &&
                    <Grid item xs={12}>
                        <MapContextProvider>
                            <PollutantRoseCardView className={clsx(classes.card, classes.pollutantRoseCard)} boxShadow={2}/>
                        </MapContextProvider>
                    </Grid>
                }
                <Grid item xs={12} md={6}>
                    <RdThresholdCardView className={clsx(classes.card, classes.thirdRowCard)} boxShadow={2}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <AQICalendarCardView className={clsx(classes.card, classes.fourthRowCard)} boxShadow={2}/>
                </Grid>
            </>
            }
            {(!isSensorIdValid && !loading) &&
                <Grid item xs={12}>
                    <DeviceNotFound className={clsx(classes.card, classes.notFoundCard)}/>
                </Grid>
            }
            {loading && <VerifyingDevice className={classes.loading}/>}

        </Grid>

    );
};

export default AnalyticScreen;
