import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {expiredSession} from "../reducers/authReducer";
import {useTranslation} from "react-i18next";
import {useMessageDialog} from "./useMessageDialog";

const useRequest = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {addDialog} = useMessageDialog();

    const handleErrorResponse = useCallback((response, unhandledErrorCallback) => {
        switch (response?.status) {
            case 401:
                const path = window.location.pathname;
                expiredSession()(dispatch);
                const lastPath = localStorage.getItem('lastPath');
                if (lastPath && lastPath === path) { // If the session expired just now show the message
                    addDialog({id: "expired_session", message: t("loginScreen.expired_session_message")});
                }
                break;
            default:
                unhandledErrorCallback && unhandledErrorCallback(response);
        }
    }, [addDialog, dispatch, t]);

    return {handleErrorResponse};
};

export default useRequest;
