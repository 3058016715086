import type from './action_types/type';
import {LOGIN_ROUTE, REGISTER_ROUTE, RESET_ROUTE} from "../constants";

const initialState={
    name:'',
    lastName:'',
    email:'',
    id:'',
    role:[],
    units:{
        pollutants: '',
        temperature:'',
        index: '',
        windSpeed:''
    },
    legalThreshold:'',
    organization:{
        name:'',
        id:'',
        map:{
            center:{
                lat:0.0,
                lon:0.0
            },
            zoom:4
        },
        legalThreshold:''
    }
    ,
    signedIn:null,
    timeZone:'',
    useInvalidFilter: false
};

export const authReducer = (state = initialState, action) =>{
    switch (action.type){
        case type.login:
            return {...action.payload};
        case type.logout:
            return {...initialState, signedIn: false};
        case type.updateProfile:
            return {...state,...action.payload};
        case type.updateOrganization:
            return {...state, organization: {...state.organization, ...action.payload}};
        default:
            return state;
    }
};

export const expiredSession = () => {
    const arr = window.location.href.split("/");
    const path = `/${arr.slice(3, arr.length).join("/")}`;
    if (
        path !== "/" &&
        !path.startsWith(LOGIN_ROUTE) &&
        !path.startsWith(REGISTER_ROUTE) &&
        !path.startsWith(RESET_ROUTE)
    ) {
        localStorage.setItem('lastPath', path);
    }
    return (dispatch)=>{
        dispatch(logoutAction());
    };
};

export const loginAction = function ({name, lastName, mail, id, units, legalThreshold, organization, role, timeZone, useInvalidFilter}) {
    return {
        type: type.login,
        payload: {name, lastName, email: mail, id, signedIn: true, units, legalThreshold, organization, role, timeZone, useInvalidFilter}
    };
};

export const logoutAction = function (){
    return {
        type:type.logout
    };
};

export const updateProfileAction = function(name, lastName, units, timeZone, legalThreshold, useInvalidFilter) {
    return {
        type:type.updateProfile,
        payload:{name, lastName, units, timeZone, legalThreshold, useInvalidFilter}
    };
};

export const updateOrganizationAction = (organization) => {
    return {
        type: type.updateOrganization,
        payload: organization
    };
};
