import React, {useCallback, useEffect, useRef, useState} from "react";
import clsx from "clsx";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {getVariableText} from "../../pollutantNames";
import {useTranslation} from "react-i18next";
import {unitsMap} from "../../unitsNames";
import useDimensions from "../../../../../hooks/useDimensions";
import TableHeaderTitle
    from "./TableHeaderTitle";
import {rdConstants} from "../../../../../constants";
import {useSelector} from "react-redux";

const tooltipOffsetParam = {
    modifiers: [
        {
            name: "offset",
            options: {
                offset: [0, -20],
            },
        },
    ],
};

const useStyles = makeStyles((_) => {
    return {
        container: {
            maxHeight: 450,
            position: "relative",
        },
        tableZbe: {
            marginLeft: 50,
            width: "calc(100% - 50px)",
        },
        headerZbe: {
            borderBottomWidth: 0
        },
        zbeCell: {
            backgroundColor: "rgba(0, 187, 201, 0.2)",
            border: "0 solid #00BBC9"
        },
        zbeRightCell: {
            borderRightWidth: 1
        },
        zbeTopCell: {
            borderTopWidth: 1,
            borderBottom: "1px solid rgba(224, 224, 224, 1)"
        },
        zbeMiddleCell: {
            borderBottom: "1px solid rgba(224, 224, 224, 1)"
        },
        zbeBottomCell: {
            borderBottomWidth: 1
        },
        zbeTopRightCell: {
            borderTopRightRadius: 8
        },
        zbeBottomRightCell: {
            borderBottomRightRadius: 8
        },
        verticalTag: {
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 50,
            backgroundColor: "#00BBC9",
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8
        },
        verticalTagText: {
            writingMode: "vertical-rl",
            transform: "rotate(180deg)",
            color: "#FFFFFF",
            fontSize: 21,
            fontWeight: 600
        }
    };
});

const RdThresholdTable = ({rdData}) => {

    const {t} = useTranslation();
    const {units} = useSelector(state => state.auth);
    const classes = useStyles();

    const legalThreshold = useSelector(state => state.auth.legalThreshold);

    const lowEmissionZoneKeys = Object.keys(rdData.lowEmissionZones);
    const notRestrictedZoneKeys = Object.keys(rdData.notRestrictedZones);

    const lowEmissionZones = {};
    lowEmissionZoneKeys.forEach((key, index) => {
        lowEmissionZones[key] = {
            ...rdData.lowEmissionZones[key],
            isZBE: true,
            isFirstZBE: index === 0,
            isMiddleCell: index > 0 && index < lowEmissionZoneKeys.length - 1,
            isLastZBE: index === lowEmissionZoneKeys.length - 1
        };
    });

    const thresholdDataKeys = [...lowEmissionZoneKeys, ...notRestrictedZoneKeys];
    const thresholdData = {
        ...lowEmissionZones,
        ...rdData.notRestrictedZones
    };

    const tableClassName = lowEmissionZoneKeys.length > 0 ? classes.tableZbe : "";
    const headerCellClassName = lowEmissionZoneKeys.length > 0 ? classes.headerZbe : "";
    const getZbeCellClasses = (key, lastCell = false) => ({
        [classes.zbeCell]: thresholdData[key].isZBE,
        [classes.zbeTopCell]: thresholdData[key].isFirstZBE,
        [classes.zbeMiddleCell]: thresholdData[key].isMiddleCell,
        [classes.zbeBottomCell]: thresholdData[key].isLastZBE,
        [classes.zbeTopRightCell]: lastCell && thresholdData[key].isFirstZBE,
        [classes.zbeBottomRightCell]: lastCell && thresholdData[key].isLastZBE,
        [classes.zbeRightCell]: lastCell
    });

    const {dimensionRef, height} = useDimensions();
    const [verticalTagStyle, setVerticalTagStyle] = useState({});
    const tableRef = useRef(null);

    useEffect(() => {
        if (tableRef.current) {
            const rows = tableRef.current.querySelectorAll("td");
            let firstZbeRowRect;
            let lastZbeRowRect;
            rows.forEach(row => {
                if (row.classList.contains(classes.zbeTopCell)) {
                    firstZbeRowRect = row.getBoundingClientRect();
                }
                if (row.classList.contains(classes.zbeBottomCell)) {
                    lastZbeRowRect = row.getBoundingClientRect();
                }
            });
            if (firstZbeRowRect && lastZbeRowRect) {
                // Calculate relative Y position and height of ZBE rows
                const firstZbeRowRelativeY = firstZbeRowRect.top - tableRef.current.getBoundingClientRect().top;
                const zbeRowsHeight = lastZbeRowRect.bottom - firstZbeRowRect.top;
                setVerticalTagStyle({
                    top: firstZbeRowRelativeY,
                    height: zbeRowsHeight
                });
            } else {
                setVerticalTagStyle({display: "none"});
            }
        }
    }, [classes, height]);

    const getCellValue = (data, isVla = false) => {
        if (data) {
            const value = isVla ? data.concentration : data.count;
            const threshold = isVla ? data.concentrationThreshold : data.countThreshold;
            return <>
                <Box fontWeight="fontWeightBold" display="inline" style={{
                    color: value > threshold ? "#d30000" : "inherit"
                }}>
                    {value}
                </Box>
                /{threshold}
                {
                    isVla
                    && ` ${unitsMap.get(data.units)}`
                }
            </>;
        }
        return <Typography variant="body2" style={{color: "#a2a2a2"}}>N/A</Typography>;
    };

    const getCellThreshold = useCallback((data, type) => {
        if (data) {
            const prefix = t(`analyticScreen.rdThreshold.${type}Tooltip`);
            return `${prefix} umbral ${data.concentrationThreshold} ${unitsMap.get(data.units)}`;
        }
        return "";
    }, [t]);

    const aggregationKeys = Object.keys(rdConstants);
    const filteredKeys = aggregationKeys.filter(key => rdData.aggregationList.includes(key));

    const getTableHeaderCells = () => {
        const mappedKeys = filteredKeys.map(key => key === rdConstants.mdmm ? "vl" : key);
        return mappedKeys.map(key => (
            <TableCell key={key} className={headerCellClassName} align="right">
                <TableHeaderTitle helperText={t(`analyticScreen.rdThreshold.${key}Help`)}>
                    {t(`analyticScreen.rdThreshold.${key}`)}
                </TableHeaderTitle>
            </TableCell>
        ));
    };

    const getTableRowCells = (key) => {
        return filteredKeys.map((aggregationKey, index) => {
            const data = thresholdData[key][aggregationKey];
            const lastCell = index === filteredKeys.length - 1;
            const isVla = aggregationKey === rdConstants.vla;
            const cell = (
                <TableCell align="right" className={clsx(getZbeCellClasses(key, lastCell))}>
                    {getCellValue(data, isVla)}
                </TableCell>
            );
            return isVla ? cell : (
                <Tooltip key={aggregationKey} title={getCellThreshold(data, aggregationKey)} arrow
                         PopperProps={tooltipOffsetParam}>
                    {cell}
                </Tooltip>
            );
        });
    };

    return (
        <TableContainer className={classes.container} ref={tableRef}>
            <Tooltip title={t(`analyticScreen.rdThreshold.legalThresholdsTooltip.${legalThreshold.toLowerCase()}`)} arrow PopperProps={{
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -60],
                        },
                    },
                ],
            }}>
                <div className={classes.verticalTag} style={verticalTagStyle}>
                    <Typography className={classes.verticalTagText}>
                        {t(`analyticScreen.rdThreshold.legalThresholds.${legalThreshold.toLowerCase()}`)}
                    </Typography>
                </div>
            </Tooltip>
            <Table stickyHeader aria-label="event table" className={tableClassName} ref={dimensionRef}>
                <TableHead>
                    <TableRow>
                        <TableCell className={headerCellClassName} align="left">
                            {t("analyticScreen.rdThreshold.pollutant")}
                        </TableCell>
                        {getTableHeaderCells()}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {thresholdDataKeys.map((key) => (
                        <TableRow key={key}>
                            <TableCell className={clsx(getZbeCellClasses(key))}>
                                <Box sx={{fontWeight: 500}}>
                                    {getVariableText(key, units)}
                                </Box>
                            </TableCell>
                            {getTableRowCells(key)}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RdThresholdTable;
