import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import DataNotFound from "../DataNotFound";
import ChartLoading from "./ChartLoading";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import PeriodSelector, {CUSTOM_RANGE} from "../../routes/private/analytics/common/PeriodSelector";
import DateRangeComponent from "../dateRange/DateRangeComponent";
import PollutantSelect from "./PollutantSelect";
import {shallowEqual} from "react-redux";
import {meteoDataSource, timeZoneOptions} from "../../../constants";
import LegendItem from "../LegendItem";
import ResolutionSelector from "../../routes/private/analytics/common/ResolutionSelector";
import clsx from "clsx";
import IconDns from "@mui/icons-material/Dns";
import IconWbSunny from "@mui/icons-material/WbSunny";
import CustomToggleButton from "../buttons/CustomToggleButton";


const ChartLayout = ({
                         loading, error, emptyData, height = "auto", chartStyles, timeZone, onTimeZoneChange, period,
                         onPeriodChange, dateRange, onDateRangeChange, resolution, onResolutionChange,
                         resolutionOptions = undefined,  pollutantList, variableNames, onPollutantSelect,
                         selectedPollutant, sideContent, legendItems, onLegendItemSelect, topContent, position,
                         meteoDataOrigin, onMeteoDataOriginChange, dataTimeZone, children
                     }) => {

    const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
    const {t} = useTranslation();

    const useStyles = useMemo(() => makeStyles({
        loading: {
            left: "50%",
            position: "relative",
            top: "50%",
            zIndex: 999
        },
        formControl: {
            minWidth: 120,
        },
        spacer:{
           marginTop:24
        },
        rightControls: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
        },
        chart: {
            minHeight: height,
            "& > .js-plotly-plot": {
                margin: 10,
                width: "100%",
                height: height
            },
            ...chartStyles
        },
        notFoundData: {
            height: height
        }
    }), [height, chartStyles]);

    const classes = useStyles();


    const handleSelectorChange = useCallback((event) => {
        if (Number(event.target.value) !== CUSTOM_RANGE) {
            onDateRangeChange(null);
            onPeriodChange(event.target.value);
        }
    }, [onDateRangeChange, onPeriodChange]);

    const onCustomPressedCallback = useCallback(() => {
        setOpenDateRangePicker(true);
    }, [setOpenDateRangePicker]);

    const updateDatePickedCallback = useCallback((newDateRange) => {
        setOpenDateRangePicker(false);
        onPeriodChange(CUSTOM_RANGE);
        onDateRangeChange(newDateRange);
    }, [setOpenDateRangePicker, onPeriodChange, onDateRangeChange]);

    const pollutantTabs = position === "top" && onPollutantSelect?.length <= 1;

    const [pollutantSelectValues, setPollutantSelectValues] = useState();

    const savingValues = useRef();

    useEffect(() => {
        if (selectedPollutant instanceof Array && (pollutantSelectValues === undefined || savingValues.current)) {
            setPollutantSelectValues(selectedPollutant);
        }
    }, [selectedPollutant, pollutantSelectValues]);

    useEffect(() => {
        if (pollutantSelectValues instanceof Array && !pollutantSelectValues.includes(null)) {
            if (shallowEqual(selectedPollutant, pollutantSelectValues)) {
                savingValues.current = false;
            } else {
                savingValues.current = true;
                pollutantSelectValues.forEach((pollutant, index) => {
                    if (pollutant !== selectedPollutant[index]) {
                        onPollutantSelect[index](pollutant);
                    }
                });
            }
        }
    }, [pollutantSelectValues, onPollutantSelect, selectedPollutant]);

    const pollutantSelectMap = [];
    if (pollutantSelectValues instanceof Array) {
        pollutantSelectValues.forEach((selectValue, index) => {
            const handleItemSelect = (pollutant) => {
                const newValues = pollutantSelectValues.map((value, selectIndex) => {
                    if (index === selectIndex) {
                        return pollutant;
                    }
                    return value === pollutant ? null : value;
                });
                setPollutantSelectValues(newValues);
            };
            const pollutantMap = {
                pollutantList,
                onPollutantSelect: handleItemSelect,
                selectedPollutant: selectValue
            };
            pollutantSelectMap.push(pollutantMap);
        });
    }

    const sideElement = (onTimeZoneChange || onPeriodChange || sideContent) && (
        <>
            {position === "top" && (
                <>
                    <Grid item xs={2}/>
                    <Grid container item xs={8} spacing={1}>

                        {pollutantTabs && (
                            <Grid item xs={12} container justifyContent={"center"}>
                                <PollutantSelect type="tabs"
                                                 disabled={loading}
                                                 onPollutantSelect={onPollutantSelect}
                                                 selectedPollutant={selectedPollutant}
                                                 pollutantList={pollutantList}/>
                            </Grid>
                        )}
                        {topContent && <Grid item xs={12} container justifyContent={"center"}>{topContent}</Grid>}
                    </Grid>
                </>
            )}
            <Grid container item xs={2} className={classes.rightControls}>
                {onTimeZoneChange && (
                    <FormControl style={{marginBottom: 12}} disabled={loading}>
                        <FormLabel component="legend">{"Data time zone"}</FormLabel>
                        <RadioGroup value={timeZone} onChange={onTimeZoneChange}>
                            <FormControlLabel value={timeZoneOptions.StationLocalTime} control={<Radio/>}
                                              label={t("local")}/>
                            <FormControlLabel value={timeZoneOptions.UTC} control={<Radio/>} label={t("utc")}/>
                        </RadioGroup>
                    </FormControl>
                )}
                {onPeriodChange && (
                    <PeriodSelector formControlClass={classes.formControl}
                                    loading={loading}
                                    period={period}
                                    handleSelectorChange={handleSelectorChange}
                                    dateRange={dateRange}
                                    onCustomPressedCallback={onCustomPressedCallback}
                                    variant="standard"
                                    margin="dense"/>
                )}
                {
                    onResolutionChange && (<ResolutionSelector formControlClass={!onPeriodChange ? classes.formControl :  clsx(classes.formControl, classes.spacer)}
                                                               loading={loading}
                                                               resolution={resolution}
                                                               handleSelectorChange={onResolutionChange}
                                                                resolutionOptions={resolutionOptions}
                                                               variant="standard"
                                                               margin="dense"/>)
                }
                {onPollutantSelect && !pollutantTabs && (
                    pollutantSelectMap.length ? (
                        pollutantSelectMap.map((pollutantItem, index) => (
                            <PollutantSelect key={index} index={index + 1}
                                             name={variableNames?.[index]}
                                             formControlClass={clsx(classes.formControl, classes.spacer)}
                                             disabled={loading || !pollutantItem.pollutantList?.length}
                                             onPollutantSelect={pollutantItem.onPollutantSelect}
                                             selectedPollutant={pollutantItem.selectedPollutant}
                                             pollutantList={pollutantItem.pollutantList}/>
                        ))
                    ) : (
                        <PollutantSelect formControlClass={clsx(classes.formControl, classes.spacer)}
                                         disabled={loading || !pollutantList?.length}
                                         onPollutantSelect={onPollutantSelect}
                                         selectedPollutant={selectedPollutant}
                                         pollutantList={pollutantList}/>
                    )
                )}
                {onMeteoDataOriginChange && (
                    <FormControl style={{marginTop: 10, marginBottom: -15}}>
                        <Typography variant="body2" color="textSecondary" style={{marginBottom: 10}}>
                            {t("analyticScreen.sensorData.temperature_and_humidity_data_source")}
                        </Typography>

                        <ToggleButtonGroup color="primary"
                                           value={meteoDataOrigin}
                                           onChange={onMeteoDataOriginChange}
                                           exclusive>
                            <CustomToggleButton compactMode value={meteoDataSource.internal} IconComp={IconDns}
                                                label={t("analyticScreen.sensorData.internal_sensors")}/>
                            <CustomToggleButton compactMode value={meteoDataSource.external} IconComp={IconWbSunny}
                                                label={t("analyticScreen.sensorData.meteo_sensors")}/>
                        </ToggleButtonGroup>
                    </FormControl>
                )}
                {sideContent}
                {!!legendItems?.length && <>
                    <Grid container spacing={2} style={{marginTop: 20}}>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary" style={{marginBottom: 10}}>
                                {t("alarmScreen.stations")}
                            </Typography>
                            {legendItems.map((item, index) => (
                                <LegendItem key={index} {...item} onItemSelected={onLegendItemSelect}/>
                            ))}
                        </Grid>
                    </Grid>
                </>}
            </Grid>
        </>
    );

    const contentSize = position !== "top" && sideElement ? 10 : 12;

    return (
        <Grid container item spacing={2}>
            {onDateRangeChange && <DateRangeComponent open={openDateRangePicker} changeState={setOpenDateRangePicker}
                                                      onDateRangePicked={updateDatePickedCallback}/>}
            {typeof dataTimeZone === "string" && (
                <Grid item xs={12}>
                    <Typography variant="body2" style={{marginLeft: 24}}>
                        {`${t("units.timeZone")}: (${dataTimeZone})`}
                    </Typography>
                </Grid>
            )}
            {position === "top" && sideElement}
            {!emptyData && (
                <Grid container item xs={contentSize} className={classes.chart}
                      style={{display: loading ? 'none' : 'block'}}>
                    {children}
                </Grid>
            )}
            {!loading && emptyData && (
                <Grid container item xs={contentSize} className={classes.notFoundData}>
                    <DataNotFound message={error || t('chart.errorFetchingData')}/>
                </Grid>
            )}
            {loading && (
                <Grid container item xs={contentSize} className={classes.notFoundData}>
                    <ChartLoading/>
                </Grid>
            )}
            {position !== "top" && sideElement}
        </Grid>
    );
};

export default ChartLayout;
