import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {unitsMap} from "../../../unitsNames";
import {DARK_PURPLE_AQI, GREEN_AQI, ORANGE_AQI, PURPLE_AQI, RED_AQI, YELLOW_AQI} from "../../../home/legend/colors";
import ChartView from "../../../../../common/chart/ChartView";

export const ROSE_CHART_MAX_ZOOM = 18;
export const ROSE_CHART_MIN_ZOOM = 13;
const ROSE_CHART_MAX_SIZE = 900;
const ROSE_CHART_MIN_SIZE = 130;

export const pollutantChartCategoryColor = [
    GREEN_AQI,
    YELLOW_AQI,
    ORANGE_AQI,
    RED_AQI,
    PURPLE_AQI,
    DARK_PURPLE_AQI
];

const getLayout = (zoom, relativePercent) => {
    if (zoom === undefined) {
        return {
            font: {size: 18},
            margin: {t: 30, b: 30, l: 30, r: 30},
            height: 500,
            width: 500,
            showlegend: false,
            dragmode: false,
            polar: {
                domain: {
                    x: [0,0.9],
                    y: [0,1]
                },
                bgcolor: "rgba(0,0,0,0)",
                barmode: "stack",
                bargap: 0.2,
                radialaxis: {ticksuffix: "%", angle: 45, dtick: 10},
                angularaxis: {direction: "clockwise"}
            },
        };
    }

    let size = (zoom - ROSE_CHART_MIN_ZOOM) * (ROSE_CHART_MAX_SIZE - ROSE_CHART_MIN_SIZE) / (ROSE_CHART_MAX_ZOOM - ROSE_CHART_MIN_ZOOM) + ROSE_CHART_MIN_SIZE;
    if (size < ROSE_CHART_MIN_SIZE) size = ROSE_CHART_MIN_SIZE;
    if (size > ROSE_CHART_MAX_SIZE) size = ROSE_CHART_MAX_SIZE;
    if (relativePercent) {
        size = size * 0.6;
    }

    return {
        font: {size: 16},
        autosize: false,
        width: size,
        height: size,
        showlegend: false,
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        dragmode: false,
        polar: {
            bgcolor: "rgba(0,0,0,0)",
            barmode: "stack",
            bargap: 0.2,
            radialaxis: {ticksuffix: "%", angle: 45, dtick: 20, range: [0, 100], visible: false},
            angularaxis: {direction: "clockwise", visible: false}
        },
        margin: {t: 0, b: 0, l: 0, r: 0}
    };
};

const PollutantRoseChart = ({data, zoom, relativePercent = false}) => {

    const {t} = useTranslation();

    const theta = useMemo(() => [
        t("analyticScreen.windRose.north"),
        t("analyticScreen.windRose.north_north_east"),
        t("analyticScreen.windRose.north_east"),
        t("analyticScreen.windRose.east_north_east"),
        t("analyticScreen.windRose.east"),
        t("analyticScreen.windRose.east_south_east"),
        t("analyticScreen.windRose.south_east"),
        t("analyticScreen.windRose.south_south_east"),
        t("analyticScreen.windRose.south"),
        t("analyticScreen.windRose.south_south_west"),
        t("analyticScreen.windRose.south_west"),
        t("analyticScreen.windRose.west_south_west"),
        t("analyticScreen.windRose.west"),
        t("analyticScreen.windRose.west_north_west"),
        t("analyticScreen.windRose.north_west"),
        t("analyticScreen.windRose.north_north_west")
    ], [t]);

    const mapData = useMemo(() => {
        if (data === null || data === undefined) return [];
        // Get the maximum value of all the data to scale the values to 100
        const maxValue = Math.max(...data.data.flatMap(item => item.r.map(Number)));
        
        return data.data.map(item => {
            const scaledR = item.r.map(value => (Number(value) / maxValue) * 100);
            const name =`${item.name} ${unitsMap.get(data.unit)}`;
            return {
                ...item,
                customdata: item.r,
                r: relativePercent ? scaledR : item.r,
                type: "barpolar",
                theta,
                marker: {color: pollutantChartCategoryColor[item.category]},
                name,
                bgcolor: "rgba(0,0,0,0)",
                opacity: 0.65,
                hovertemplate: `%{theta}<br><b>%{customdata}%</b>`,
                hoverlabel: {namelength: 0},
            };
        });
    }, [data, theta, relativePercent]);

    const layout = useMemo(() => getLayout(zoom, relativePercent), [zoom, relativePercent]);

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            height: zoom === undefined ? 500 : "auto"
        }}>
            <ChartView
                layout={layout}
                data={mapData}
                config={{displayModeBar: false}}
            />
        </div>
    );
};

export default PollutantRoseChart;
