import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const scatterPlotRequest = (temp, pollutantUnit, humidityUnit, stationId, sensorX, sensorY, init, end, callback) => {
    const url = `/station-data/${stationId}/scatter-plot`;
    const params = {
        temperature: temp,
        pollutants: pollutantUnit,
        humidity: humidityUnit,
        time: init,
        endtime: end,
        x: sensorX,
        y: sensorY
    };

    webClient.get(url, {params})
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};