import {getLineBarChartData} from "../dayOfWeek/dayOfWeekChart";
import {getYRange} from "../chartRangeUtil";

export const getYearChartLayout = (monthOfYear,unit,title, data) => {

    return {
        xaxis9: {
            anchor: `y9`,
            showgrid: true,
            domain: [0.35, 0.65],
            side: "bottom",
            mirror: true,
            ticklen: 8,
            title: {
                text:title
            },
            tickmode: "array",
            linecolor: '#000000',
            linewidth: 1,
            autotick: false,
            tickvals: Array.from({length:  12 }, (_, i) => i + 1),
            ticktext: monthOfYear
        },
        yaxis9: {
            anchor: `x9`,
            domain: [0, 0.5],
            mirror:  true,
            showline: true,
            linecolor: '#000000',
            linewidth: 1,
            ticklen: 8,
            title: {text:  unit, standoff:10},
            zeroline:false,
            range: getYRange(data)
        }
    };
};

export const getYearData = (pollutant, unit, units, data) => {
    return getLineBarChartData(pollutant, unit, units, data,true);
};
