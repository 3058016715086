import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";
import {AUTO_RESOLUTION} from "../../components/routes/private/analytics/common/ResolutionSelector";

/**
 *
 * @param temp {string}
 * @param pollutantUnit {string}
 * @param humidityUnit {string}
 * @param stationId {string}
 * @param init {string}
 * @param end {string | undefined}
 * @param dataTimeZone {string}
 * @param useValidationFilter {boolean | undefined}
 * @param resolution
 * @param callback {function}
 */
export const sensorDataRequest = ({temp, pollutantUnit, humidityUnit, stationId, init, end, dataTimeZone,
                                      useValidationFilter, resolution = AUTO_RESOLUTION}, callback) => {

    const url = `/station-data/${stationId}/sensor-data`;

    const params = {
        temperature: temp,
        pollutants: pollutantUnit,
        humidity: humidityUnit,
        dataTimeZone: dataTimeZone,
        time: init,
        endtime: end,
        useValidationFilter: useValidationFilter,
        resolution: resolution !== AUTO_RESOLUTION ? resolution : undefined
    };

    webClient.get(url, {params})
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};
