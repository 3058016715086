import './chart_style.css';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import ChartLayout from "../../../../common/chart/ChartLayout";
import CalendarMonthSelector from "../../analytics/calendar_card/CalendarMonthSelector";
import ChartView from "../../../../common/chart/ChartView";
import {plotConfig, sensorOptions} from "../../analytics/temporal_variation_card/tempChartConfig";
import {
    abbreviatedWeekDaysTranslation, monthOfYeasTranslation,
    weekDayTranslation
} from "../../analytics/temporal_variation_card/viewTextTranslateKeys";
import {useTranslation} from "react-i18next";
import {unitsMap} from "../../unitsNames";
import {
    getHourDayOfWeekChartLayout,
    getHourDayOfWeekData
} from "./week/weekChart";
import {getHourlyChartLayout, getHourlyData} from "./hour/hourChart";
import {getYearChartLayout, getYearData} from "./year/yearChart";
import {
    getDayOfWeekChartData,
    getDayOfWeekChartLayout
} from "./dayOfWeek/dayOfWeekChart";
import {
    pollutantTrendRequest
} from "../../../../../requests/analytics/pollutantTrendRequest";
import useMessage from "../../../../../hooks/useMessage";
import {useSelector} from "react-redux";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {map} from "async";
import _, {isEqual} from "lodash";
import {getAvailableSensors} from "../../../../../utils/stationUtil";
import useRequest from "../../../../../hooks/useRequest";
import StationCheckboxSelector from "../../../../common/StationCheckboxSelector";
import {compareStationsColors} from "../CompareScreen";
import {Card, Grid} from "@mui/material";
import CardTitle from "../../../../common/card_views/CardTitle";
import HelpPopup from "../../../../common/HelpPopup";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";

const TemporalVariationCardView = ({className}) => {
    const {anchorEl, setAnchorEl, handleHelpClose} = useAnchorEl();
    const {t} = useTranslation();
    const HOURLY_CHART_TITLE = t("analyticScreen.temporal_variation.hourly_evolution");
    const YEAR_CHART_TITLE = t("analyticScreen.temporal_variation.monthly_evolution");
    const DAY_OF_WEEK_CHART_TITLE = t("analyticScreen.temporal_variation.evolution_by_day_of_the_week");
    const {setError, setInfo} = useMessage();
    const {handleErrorResponse} = useRequest();
    const isMounted = useMountComponent();
    const {units} = useSelector(state => state.auth);
    const selectCompareStations = useSelector(state => state.dashboardUI.selectCompareStations, isEqual);
    const [pollutantList, updatePollutantList] = useState([]);
    const [selectedPollutant, updateSelectedPollutant] = useState(null);
    const {weekDays, abbreviatedWeekDays, monthOfYeas} = useMemo(() => {
        return {
            weekDays: weekDayTranslation.map(item => t(item)),
            abbreviatedWeekDays: abbreviatedWeekDaysTranslation.map(item => t(item)),
            monthOfYeas: monthOfYeasTranslation.map(item => t(item))
        };
    }, [t]);
    const [loading, updateLoading] = useState(false);
    const [dataTimeZone, setDataTimeZone] = useState(null);
    const [chartLayout, updateChartLayout] = useState(null);
    const [{
        selectedDate,
        chartData,
        error,
    }, updateState] = useState({
        selectedDate: new Date(),
        chartData: null,
        error: null,
    });
    const [stationFilter, updateStationFilter] = useState([]);
    const [rawData, updateRawData] = useState([]);

    useEffect(() => {
        let pollutantList = getAvailableSensors(selectCompareStations).map(it => (it.id)).filter(item => sensorOptions.includes(item));
        updatePollutantList(pollutantList);
        updateSelectedPollutant(pollutantList[0]);
    }, [selectCompareStations]);

    useEffect(() => {
        if (rawData.length > 0)
            updateLayout(selectedPollutant, rawData);
        updateStationFilter(rawData.map(item => {
            return {name: item.stationId, checked: true, color: compareStationsColors[item.index]};
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawData]);

    useEffect(() => {
        if (rawData.length > 0) {
            const visibleStations = stationFilter.filter(item => item.checked).map(item => item.name);
            const filteredData = rawData.filter(item => visibleStations.includes(item.stationId));
            if (filteredData.length > 0 && selectedPollutant !== null)
                updateChartData(selectedPollutant, filteredData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stationFilter]);

    useEffect(() => {
        async function fetchData() {
            updateLoading(true);
            updateStationFilter([]);
            updateState(state => ({...state, chartData: null, error: null, chartLayout: null}));
            const params = {
                pollutants: units.pollutants,
                temperature: units.temperature,
                humidity: units.humidity,
                time: new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 1, 0, 0, 0)).toISOString(),
                sensor: selectedPollutant
            };
            try {
                const data = await map(selectCompareStations.map(item => item.id), async (stationId) => {
                    const result = await pollutantTrendRequest({...params, stationId});
                    if (result) return {...result, stationId};
                    return undefined;
                });
                updateLoading(false);
                const filteredData = data.filter(item => item !== undefined);
                if (filteredData.length === 0) {
                    updateState(state => {
                        return {...state, error: t("compareScreen.stationData.comparable_data_not_found")};
                    });
                } else
                    updateRawData(data.map((item, index) => {
                        return item ? {...item, index} : undefined;
                    }).filter(item => item !== undefined));
            } catch (e) {
                updateLoading(false);
                updateState(state => {
                    return {...state, error: t("analyticScreen.temporal_variation.could_not_update_station_data")};
                });
                handleErrorResponse(e?.response?.data, response => {
                    setError(response, false, "analyticScreen.temporal_variation.could_not_update_station_data");
                });
            }
        }

        if (selectedPollutant !== null)
            fetchData();
    }, [selectedPollutant, selectedDate, units, handleErrorResponse, setError, setInfo, t,
        selectCompareStations]);


    const handleDateChange = useCallback((newDate) => {
        updateState(state => ({...state, selectedDate: newDate}));
    }, []);

    const pollutantCallback = useCallback((pollutant) => {
        updateSelectedPollutant(pollutant);
    }, []);

    const updateChartData = useCallback((pollutant, pollutantData) => {
        if (isMounted.current) {

            const data = pollutantData.map((item) => {

                const variable = Object.keys(item)[0];
                const data = item[variable];
                const stationId = item.stationId;
                const unit = unitsMap.get(data.units);
                const hourDayOfWeek = data.hourDayOfWeek;
                const hourData = data.hourOfDay;
                const yearData = data.yearData;
                const dayOfWeekData = data.dayOfWeek;

                return [
                    ...getHourDayOfWeekData(pollutant, unit, hourDayOfWeek, item.index, stationId),
                    ...getHourlyData(pollutant, unit, hourData, item.index, stationId),
                    ...getYearData(pollutant, unit, yearData, item.index),
                    ...getDayOfWeekChartData(pollutant, unit, dayOfWeekData, item.index)
                ];
            });

            const chartData = [...(_.flattenDeep(data))];

            updateState(state => ({...state, chartData}));
        }
    }, [isMounted]);


    const updateLayout = useCallback((pollutant, pollutantData) => {

        const variable = Object.keys(pollutantData[0])[0];
        const data = pollutantData[0][variable];

        const dataTimeZone = pollutantData.reduce((acc, item) => {
            acc[item.stationId] = item.timeZone;
            return acc;
        }, {});

        const unit = unitsMap.get(data.units);
        const hourDayOfWeek = pollutantData.map(item => item[variable].hourDayOfWeek);
        const hourData = pollutantData.map(item => item[variable].hourOfDay);
        const yearData = pollutantData.map(item => item[variable].yearData);
        const dayOfWeekData = pollutantData.map(item => item[variable].dayOfWeek);

        const chartLayout = {
            barmode: 'stack',
            autosize: true,
            showlegend: false,
            hovermode: "x unified",
            ...getHourDayOfWeekChartLayout(false, weekDays, unit, hourDayOfWeek),
            ...getHourlyChartLayout(unit, HOURLY_CHART_TITLE, hourData),
            ...getYearChartLayout(monthOfYeas, unit, YEAR_CHART_TITLE, yearData),
            ...getDayOfWeekChartLayout(abbreviatedWeekDays, false, unit, DAY_OF_WEEK_CHART_TITLE, dayOfWeekData),
        };

        updateChartLayout(chartLayout);
        setDataTimeZone(dataTimeZone);
    }, [DAY_OF_WEEK_CHART_TITLE, HOURLY_CHART_TITLE, YEAR_CHART_TITLE, abbreviatedWeekDays, monthOfYeas, weekDays]);

    const onStationFilterChange = useCallback((name, checked) => {
        const result = [...stationFilter.map(item => item.name === name ? {...item, checked} : item)];
        if (!result.every(item => !item.checked))
            updateStationFilter(result);
    }, [stationFilter]);

    return (
        <Card className={className}>
            <Grid container direction={"row"}>
                <Grid container item xs={12} md={9} alignItems={"center"} alignContent={"center"}>
                    <CardTitle title={t("analyticScreen.temporal_variation.title")} setAnchorEl={setAnchorEl}/>
                </Grid>
                <Grid item xs={12} md={3}>
                    <CalendarMonthSelector
                        date={selectedDate}
                        handleDateChange={handleDateChange}
                        disable={loading}/>
                </Grid>
                <ChartLayout loading={loading}
                             emptyData={!chartData}
                             height={780}
                             error={error}
                             pollutantList={pollutantList}
                             onPollutantSelect={pollutantCallback}
                             selectedPollutant={selectedPollutant}
                             chartStyles={{"& .modebar": {left: "50%"}}}
                             position={"top"}
                             sideContent={<></>}
                             topContent={
                                 stationFilter && <StationCheckboxSelector stationList={stationFilter}
                                                                           onCheckChange={onStationFilterChange}
                                                                           dataTimeZone={dataTimeZone}

                                 />
                             }
                >
                    <ChartView
                        layout={chartLayout}
                        data={chartData}
                        config={plotConfig}
                    />
                </ChartLayout>
            </Grid>
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose}
                       message={t(`analyticScreen.temporal_variation.en_analytics_temp_variation`)}/>
        </Card>
    );
};

export default TemporalVariationCardView;
