import {webClient} from "../../config/axios";
import {handleError} from "../handleRequestError";

export const sensorStatisticRequest = (temp, pollutantUnits, humidityUnits, stationId, init, end, callback) => {
    const url = `/station-data/${stationId}/sensor-statistics`;
    const params = {
        temperature: temp,
        pollutants: pollutantUnits,
        humidity: humidityUnits,
        time: init,
        endtime: end
    };
    webClient.get(url, {params})
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};