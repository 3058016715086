import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import RoseChartMapView from "./map/RoseChartMapView";
import {shallowEqual, useSelector} from "react-redux";
import {DEFAULT_PERIOD, pollutants} from "../../../../../constants";
import RoseChartLegend from "./legend/RoseChartLegend";
import {pollutantRoseRequest} from "../../../../../requests/analytics/pollutantRoseRequest";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import useRequest from "../../../../../hooks/useRequest";
import CardLayout from "../../../../common/card_views/CardLayout";
import ChartLayout from "../../../../common/chart/ChartLayout";
import useMessage from "../../../../../hooks/useMessage";
import processPollutantRoseData from "../../../../../utils/processPollutantRoseData";
import MapLayer from "./map_layer/MapLayer";
import PollutantRoseChart from "./chart/PollutantRoseChart";
import {getStationSensors} from "../../../../../utils/stationUtil";


const PollutantRoseCardView = ({className}) => {

    const [loading, updateLoading] = useState(false);
    const [data, updateData] = useState(null);

    const [period, setPeriod] = useState(DEFAULT_PERIOD);
    const [dateRange, setDateRange] = useState(null);
    const selectedRange = useMemo(() => getDateRange(period, dateRange), [period, dateRange]);
    const selectedStation = useSelector(state => state.dashboardUI.selectedStation);
    const [selectedPollutant, updateSelectedPollutant] = useState(null);
    const {units} = useSelector(state => state.auth);
    const {handleErrorResponse} = useRequest();
    const {t} = useTranslation();
    const notFoundMessage = useMemo(() => {
        return t("dataNotFound");
    }, [t]);
    const {setError} = useMessage();
    const [error, updateError] = useState(notFoundMessage);

    const stationSensors = useSelector(state => {
        const stations = state.dashboardUI.stations;
        const station = stations.find(item => item.id === selectedStation);
        return getStationSensors(station);
    }, shallowEqual);

    const pollutantFilteredList = useMemo(() => (
        pollutants.filter(item => stationSensors.includes(item))
    ), [stationSensors]);

    const position = useSelector(state => {
        const stations = state.dashboardUI.stations;
        const station = stations.find(item => item.id === selectedStation);
        return station?.position;
    }, shallowEqual);

    const loadData = useCallback(async () => {
        if (!pollutantFilteredList.includes(selectedPollutant)) return;

        updateLoading(true);
        try {
            updateError("");
            const requestData = await pollutantRoseRequest(units.temperature, units.pollutants, selectedStation,
                selectedPollutant, selectedRange[0], selectedRange[1]);
            const newData = processPollutantRoseData(requestData);
            updateLoading(false);
            updateData(newData);
        } catch (data) {
            if (data.status === 404) {
                updateData(null);
                updateError(notFoundMessage);
            } else {
                handleErrorResponse(data, response => {
                    updateError(t("analyticScreen.sensorData.could_not_update_station_data"));
                    setError(response, false,
                        "analyticScreen.sensorData.could_not_update_station_data");
                });
            }
            updateLoading(false);
        }
    }, [pollutantFilteredList, selectedPollutant, units.temperature, units.pollutants, selectedStation, selectedRange,
        notFoundMessage, t, handleErrorResponse, setError]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        // If the selected sensor is not available, we will set the first available sensor as the selected sensor
        updateSelectedPollutant(oldState => (
            pollutantFilteredList.includes(oldState) ? oldState : pollutantFilteredList[0]
        ));
    }, [pollutantFilteredList]);

    const sensorData = data || null;

    const handlePollutantChange = (value) => {
        updateSelectedPollutant(value);
    };

    return (
        <CardLayout className={className} title={t("analyticScreen.pollutantRose.title")}
                    helpText={t("analyticScreen.pollutantRose.en_analytics_pollutant_rose")}>
            <ChartLayout error = {error} loading={loading} emptyData={!data} height={650} period={period} onPeriodChange={setPeriod}
                         dateRange={dateRange} onDateRangeChange={setDateRange} pollutantList={pollutantFilteredList}
                         onPollutantSelect={handlePollutantChange} selectedPollutant={selectedPollutant} sideContent={(
                !loading && sensorData &&
                <>
                    {position && <MapLayer/>}
                    <RoseChartLegend data={sensorData}/>
                </>
            )}>
                <Grid item xs={12}>
                    {position ? (
                        <RoseChartMapView position={position} data={sensorData}/>
                    ) : (
                        <PollutantRoseChart data={data}/>
                    )}
                </Grid>
            </ChartLayout>
        </CardLayout>
    );
};

export default PollutantRoseCardView;
