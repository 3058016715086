import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';
import {LOGIN_ROUTE} from "../../../constants";

export const PrivateRoute = (privateRouteProps) => {
    const {
        isAuthenticated,
        component: Component,
        children,
        ...rest
    } = privateRouteProps;

    return (
        <Route {...rest} render={(props) => (
            isAuthenticated ? (
                Component ? <Component { ...props } /> : children
            ) : <Redirect to={LOGIN_ROUTE} />
        )} />
    );
};

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired
};


