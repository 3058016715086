import {Backdrop, Card, Divider, Fade, Grid, Menu, MenuItem, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import PeriodChart from "./PeriodChart";
import React, {useCallback, useEffect, useRef, useState} from "react";
import ChartLayout from "../../../../common/chart/ChartLayout";
import {usePeriodContext} from "../common/PeriodContext";
import {compareStationsDataRequest} from "../../../../../requests/compare/compareStationsDataRequest";
import {useSelector} from "react-redux";
import {getChangeDetail} from "../../../../../requests/validation/getChangeDetail";
import {dateToUTCServerFormatString} from "../../../../../utils/dateToUtcStringConverter";
import {getFormattedDateTime, msToHrMinSec} from "../../../../../utils/getFormattedDateTime";
import HelpButton from "../../../../common/buttons/HelpButton";
import {periodTypes} from "bettairplaformutil/src/validationUtil";
import {periodTypeColor, timeZoneOptions} from "../../../../../constants";
import {getVariableText} from "../../pollutantNames";
import moment from "moment-timezone";

const useStyles = makeStyles({
    content: {
        marginRight: 20,
        marginLeft: 20
    },
    card: {
        paddingTop: 16,
        paddingBottom: 10,
    },
    title: {
        marginLeft: 30,
        color: "#434343"
    },
    subtitle: {
        margin: "0 30px 16px"
    },
    topCenterItems: {
        marginBottom: 16,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    topRightItems: {
        margin: "0 30px 16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    buttons: {
        marginRight: 30,
        display: "flex",
        marginTop: 10,
        justifyContent: "flex-end"
    },
    divider: {
        marginBottom: 32
    },
    divider2: {
        marginTop: 8
    },
    popupContainer: {
        width: 1000
    },
    legendSquare: {
        width: 12,
        height: 12,
        marginRight: 5,
        border: "1px solid #00000080",
        borderRadius: 3
    },
    addedPeriodLegendSquare: {
        width: 12,
        height: 12,
        marginRight: 5,
        backgroundColor: "rgba(192, 0, 0, 0.4)",
        border: "1px solid rgba(192, 0, 0, 0.4)",
        borderRadius: 3
    },
    removedPeriodLegendSquare: {
        width: 12,
        height: 12,
        marginRight: 5,
        backgroundColor: "rgba(0, 255, 0, 0.4)",
        border: "1px solid rgba(0, 255, 0, 0.4)",
        borderRadius: 3
    }
});

const HistoryDetailPopup = ({row, onClose, navigateToDate}) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [rawData, setRawData] = useState(null);
    const [periods, setPeriods] = useState([]);
    const [addedPeriods, setAddedPeriods] = useState([]);
    const {selectedStation} = usePeriodContext();
    const selectedStationData = useSelector(state => {
        return state.dashboardUI.stations.find(station => station.id === selectedStation);
    });
    const {units} = useSelector(state => state.auth);
    const dataTimeZone = useSelector(state => state.auth.timeZone);
    const anchorEl = useRef(null);
    const [menuPosition, setMenuPosition] = useState(null);
    const [hoverDate, setHoverDate] = useState(null);

    const timeZone = (dataTimeZone === timeZoneOptions.StationLocalTime && selectedStationData.position?.locationInfo?.timeZone)
        || timeZoneOptions.UTC;
    const [periodsDuration, setPeriodsDuration] = useState({});

    useEffect(() => {
        if (row && !rawData) {
            setLoading(true);
            const variablesToDownload = [row.sensor];

            const time = new Date(row.time);
            time.setDate(time.getDate() + 1);
            const endDate = new Date(row.endTime);

            compareStationsDataRequest({
                    stations: [selectedStation],
                    pollutants: units.pollutants,
                    variables: variablesToDownload,
                    temperature: units.temperature,
                    humidity: units.humidity,
                    time: dateToUTCServerFormatString(time),
                    endtime: dateToUTCServerFormatString(endDate),
                    dataTimeZone,
                    useValidationFilter:false
                }, (err, data) => {
                    setLoading(false);
                    if (!err) {
                        const stationData = data[selectedStation];
                        setRawData(stationData);
                    }
                }
            );
        } else if (!row && rawData) {
            setRawData(null);
        }
    }, [row, selectedStation, units.temperature, units.pollutants, units.humidity, rawData, dataTimeZone, timeZone]);

    useEffect(() => {
        if (row) {
            getChangeDetail(row).then(data => {
                setPeriods(data.mergedHistory.map(({time, endtime, type}) => ({
                    time,
                    endTime: endtime,
                    type
                })));
                setAddedPeriods(data.periods.map(({time, endtime, type}) => ({
                    time,
                    endTime: endtime,
                    type
                })));
            });
        } else {
            setPeriods([]);
            setAddedPeriods([]);
        }
    }, [row]);

    useEffect(() => {
        const totalFlagTimeInMs = {};

        const duration = (period)=>{
            return new Date(period.endTime).getTime() - new Date(period.time).getTime();
        };

        addedPeriods.forEach(item => {
            if(!totalFlagTimeInMs[item.type])
                totalFlagTimeInMs[item.type] = duration(item);
            else
                totalFlagTimeInMs[item.type] += duration(item);
        });

        setPeriodsDuration(totalFlagTimeInMs);
    },[addedPeriods]);

    const handleOnClick = useCallback((e) => {
        e.preventDefault();
        setMenuPosition({left: e.clientX, top: e.clientY});
    }, []);

    const handleClose = useCallback(() => {
        setMenuPosition(null);
    }, []);

    const onHover = useCallback((value)=>{
        if(menuPosition === null){
            setHoverDate(value.points.at(0).x);
        }
    },[menuPosition]);

    const handleNavigateToDateSelected = useCallback(()=>{
        navigateToDate(moment.tz(hoverDate, timeZone).toDate());
        setMenuPosition(null);
    },[hoverDate, navigateToDate, timeZone]);

    return (
        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={!!row}>
            <Card className={classes.card} data-testid={"history-detail-popup"}>
                <Grid className={classes.popupContainer} container justifyContent="center" alignItems="center">
                    <Grid item xs={3}>
                        <Typography variant={"h6"} className={classes.title}>{t("validation.edited_by")}</Typography>
                        <Typography className={classes.subtitle} variant={"subtitle1"}>
                            {row?.user}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <div className={classes.topCenterItems}>
                            <Typography variant={"h6"} className={classes.title}>
                                {`${selectedStationData?.alias ? `${selectedStationData.alias} - ` : ""}`}
                                {getVariableText(row?.sensor)}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.topRightItems}>
                            <HelpButton helpText={t("validation.history_detail_help_text")}/>
                            <Typography variant={"subtitle1"}>
                                {!!row && getFormattedDateTime(row.created)}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid item xs={12} className={classes.content} onContextMenu={handleOnClick}>
                        <ChartLayout loading={loading} height={300} position="top">
                            {!!rawData && (
                                <PeriodChart rawData={rawData}
                                             selectedSensor={row?.sensor}
                                             periods={periods}
                                             addedPeriods={addedPeriods}
                                             timeZone={timeZone}
                                             onHover={onHover}
                                />
                            )}
                        </ChartLayout>
                    </Grid>
                    <Grid item container xs={12} alignItems="center" justifyContent="center">
                        {Object.keys(periodTypes).map((key) => (
                            <Grid key={key} item xs={2} display="flex" alignItems="center" justifyContent="center">
                                <div className={classes.legendSquare} style={{
                                    backgroundColor: periodTypeColor[periodTypes[key]]
                                }}/>
                                <Typography variant={"subtitle1"}>
                                    {t(`validation.periodType.${key}`)}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item container xs={12}>
                        {Object.keys(periodTypes).map((key) => (
                            <Grid key={key} item xs={2} display="flex" alignItems="center" justifyContent="center" >
                                <Typography variant={"subtitle1"}>
                                    {`${msToHrMinSec(periodsDuration[periodTypes[key]])}`}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            <Button onClick={onClose}>{t("action.close")}</Button>
                        </div>
                    </Grid>
                </Grid>
                <div
                    ref={anchorEl}
                    style={{
                        position: 'absolute',
                        ...menuPosition
                    }}></div>
                {menuPosition !== null &&  <Menu
                    id="fade-menu"
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    onContextMenu={e => e.preventDefault()}
                    anchorEl={anchorEl.current}
                    open={true}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <MenuItem onClick={handleNavigateToDateSelected}>
                        {t("validation.showPeriodInTable")}</MenuItem>
                </Menu>}
            </Card>
        </Backdrop>
    );
};

export default HistoryDetailPopup;
