import {eventMessage} from "./webSocketProtocol";
import {expiredSession} from "../reducers/authReducer";
import {profileRequest} from "../requests/profile/profileRequest";
import {urlBase} from "../config/axios";
import {handleSentryError} from "../handleSentryError";

let reconnectCounter = 0;
const maxReconnectAttempts = 5;
let timerId = null;

export const webSocket = (dispatch,webSockedRef,sockedFailToConnectCallback,updateDataCallbackRef,
                          webSocketStatusCallback, alarmModuleEnabled)=>{

    timerId = null;
    const socket  = new WebSocket(`wss://${urlBase}`);
    webSockedRef.current = socket;

    socket.onopen = function() {
        webSocketStatusCallback(true);
        reconnectCounter = 0;
        keepAlive(webSockedRef.current);
    };

    socket.onmessage = (messageEvent) =>{
        eventMessage(socket,dispatch,messageEvent,updateDataCallbackRef, alarmModuleEnabled);
    };

    socket.onerror = ()=> {
        cancelKeepAlive();
    };

    socket.onclose = (e) => {
        webSocketStatusCallback(false);
        cancelKeepAlive();
        if (e.code !== 1000){
            reconnectCounter = reconnectCounter+1;
            if(reconnectCounter <= maxReconnectAttempts){
                setTimeout(()=>{
                    tryToReconnect();
                },5000);
            }else{
                handleSentryError(`Error trying to reconnect 
                websocket after ${maxReconnectAttempts} attempts`);
                sockedFailToConnectCallback();
                reconnectCounter = 0;
            }
        }
    };

    function keepAlive(webSocket) {
        const timeout = 55000;
        if(webSocket === null || webSocket === undefined){return;}
        if (webSocket.readyState === webSocket.OPEN) {
            webSocket.send('Hi');
        }
        timerId = setTimeout(()=>{
            keepAlive(webSocket);
        }, timeout);
    }

    function cancelKeepAlive() {
        if (timerId) {
            clearTimeout(timerId);
        }
    }

    const tryToReconnect = () => {
        profileRequest(( err,resp) => {
            if (err && resp.status === 401) {
                expiredSession()(dispatch);
            }
            else {
                webSocket(dispatch,webSockedRef,sockedFailToConnectCallback,updateDataCallbackRef,webSocketStatusCallback, alarmModuleEnabled);
            }
        });
    };
};
