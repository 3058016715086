import { shallowEqual, useSelector } from "react-redux";
import useMessage from "./useMessage";
import { useCallback, useState } from "react";
import { aqiRequest } from "../requests/analytics/aqiRequest";
import {handleSentryError} from "../handleSentryError";
import {getVariableName} from "../components/routes/private/pollutantNames";

const useStationsData = () => {
    const { setError } = useMessage();
    const { units } = useSelector(state => state.auth);
    const stationIds = useSelector(state => (
        state.dashboardUI.stations.map(({ id }) => id)
    ), shallowEqual);
    const [loading, setLoading] = useState(false);
    const [stationsAqiData, setStationsAqiData] = useState([]);

    const updateStationsAqiData = useCallback(async () => {
        if (!stationIds || stationIds.length === 0 || !units?.index) return;
        setLoading(true);
        try {
            const aqiData = await aqiRequest();
            const newStationsAqiData = stationIds.map(id => {
                const stationAqiData = aqiData.find(aqi => aqi.station === id);
                const stationAqiIndex = stationAqiData?.data?.[0].y[units.index]?.value;
                const predominantPollutant = getVariableName(stationAqiData?.data?.[0].y[units.index]?.pollutant, units);
                return { id, index: stationAqiIndex, predominantPollutant };
            });
            setStationsAqiData(newStationsAqiData);
        } catch (err) {
            setError("chart.errorFetchingData",
                false);
            handleSentryError(err);
        }
        setLoading(false);
    }, [setError, stationIds, units]);

    return { stationsAqiData, updateStationsAqiData, loading };
};

export default useStationsData;
