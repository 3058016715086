import {webClient} from "../../config/axios";

export const rdThresholdRequest = (pollutantUnit, stationId, year) => {
    const url = `/station-data/${stationId}/rd-metrics?time=${year.toISOString()}&pollutants=${pollutantUnit}`;
    return webClient.get(url).then(response => {
        return response.data;
    }).catch(error => {
        if (error.request?.status === 404) return {};
        else if(error.request?.status === 412) return null;
        throw error;
    });
};
