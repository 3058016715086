import React from 'react';
import {Checkbox, FormControlLabel, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";

const StationCheckboxSelector = ({stationList, onCheckChange, dataTimeZone}) => {

    const {t} = useTranslation();

    return (
        <div style={{display: "flex", flexWrap: "wrap"}}>
            {stationList.map((station, index) => {
                return (
                    <div key={index} style={{display: "flex", flexDirection: "column", alignItems: "center", margin: "10px"}}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={station.checked}
                                          onChange={event => onCheckChange(event.target.name, event.target.checked)}
                                          name={station.name}
                                          sx={{
                                              color: station.color,
                                              '&.Mui-checked': {
                                                  color: station.color,
                                              },
                                          }}
                                />
                            }
                            label={station.name}
                        />
                        <div style={{ display: "flex", alignItems: "center"}}>
                            <Tooltip title={t("units.timeZone")}>
                                <span style={{fontSize: "0.8em"}}>{`(${dataTimeZone[station.name]})`}</span>
                            </Tooltip>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default StationCheckboxSelector;
