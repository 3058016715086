import {useEffect, useMemo} from "react";
import {usePeriodContext} from "./common/PeriodContext";
import {useSelector} from "react-redux";
import {dateToUTCServerFormatString} from "../../../../utils/dateToUtcStringConverter";
import useMessage from "../../../../hooks/useMessage";
import {getAvailableSensors} from "../../../../utils/stationUtil";
import useRequest from "../../../../hooks/useRequest";
import {pollutants, sensor} from "../../../../constants";
import {initialState} from "../analytics/sensor_data_card/initialConfig";
import {sensorDataRequest} from "../../../../requests/analytics/sensorDataRequest";

const useSensorData = (isValidationChart = false) => {
    const {setError, setInfo} = useMessage();
    const {handleErrorResponse} = useRequest();
    const {selectedStation, setStationSensors, setRawData, setLoading,
        selectedPeriod} = usePeriodContext();
    const selectedStationData = useSelector(state => {
        return state.dashboardUI.stations.find(station => station.id === selectedStation);
    });
    const {units, timeZone: dataTimeZone} = useSelector(state => state.auth);

    useEffect(() => {
        if (selectedStationData) {
            const newStationSensors = getAvailableSensors([selectedStationData]).map(it => it.id).filter(id => (
                [...pollutants, sensor.temperature, sensor.rh, sensor.pressure].includes(id)
            ));
            setStationSensors(newStationSensors);
        }
    }, [selectedStationData, setStationSensors]);

    const selectedRange = useMemo(() => {
        if (selectedPeriod) {
            const endDate = new Date(selectedPeriod.endDate);
            endDate.setDate(endDate.getDate() - 1);
            return [
                dateToUTCServerFormatString(selectedPeriod.startDate),
                dateToUTCServerFormatString(endDate)
            ];
        }
    }, [selectedPeriod]);

    useEffect(() => {
        if (selectedRange && selectedStation) {
            setLoading(true);
            setRawData(initialState.rawData);

            sensorDataRequest({
                temp: units.temperature,
                pollutantUnit: units.pollutants,
                humidityUnit: units.humidity,
                stationId: selectedStation,
                init: selectedRange[0],
                end: selectedRange[1],
                dataTimeZone,
                useValidationFilter:!isValidationChart
            }, (err, data) => {
                setLoading(false);
                if (!err) {
                    setRawData(data);
                } else {
                    if (data.status === 404) {
                        setInfo("analyticScreen.sensorData.sensor_data_not_found");
                    } else {
                        handleErrorResponse(data, response => {
                            setError(response,
                                false,
                                "analyticScreen.sensorData.could_not_update_station_data");
                        });
                    }
                    setRawData(null);
                }
            });
        }
    }, [units, selectedStation, selectedRange, dataTimeZone, setInfo, handleErrorResponse, setError, setLoading, setRawData, isValidationChart]);
};

export default useSensorData;
