import React from 'react';
import {ToggleButton, ToggleButtonGroup} from "@mui/material";

const ToggleButtons = ({className, options, onChange, value, disabled = false}) => {
    return (
        <ToggleButtonGroup
            className={className}
            value={value}
            exclusive
            onChange={onChange}
        >
            {options.map(({label, value}) => (
                <ToggleButton disabled={disabled}
                            data-testid={"toggle-"+value}
                            key={value}
                            value={value}>
                    {label}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

export default ToggleButtons;
