import "./map_style.css";
import styles from "./RoseChartMapView.module.scss";
import React, {useEffect, useRef, useState} from 'react';
import mapBoxGL from 'mapbox-gl';
import {MAP_API_KEY} from "../../../../../../constants";
import clsx from "clsx";
import ReactDOM from "react-dom";
import PollutantRoseChart from "../chart/PollutantRoseChart";
import {useMapContext} from "../map_layer/MapContext";
import {mapboxMapStyles} from "../../../home/HomeScreen";
import useTryWebGL from "../../../../../../hooks/useTryWebGL";

export const ROSE_CHART_INITIAL_ZOOM = 15;

const RoseChartMapView = ({position,data}) => {

    const mapRef = useRef(null);
    const roseMarkerRef = useRef(null);
    const [zoom,updateZoom] = useState(ROSE_CHART_INITIAL_ZOOM);
    const { mapType} = useMapContext();
    const {tryWebGL} = useTryWebGL();

    const mapboxMapStyle = mapboxMapStyles[mapType];


    useEffect(() => {

        const bounds = position === undefined ? null : [
            [position.long - 4, position.lat - 4], // Southwest coordinates
            [position.long + 4, position.lat + 4] // Northeast coordinates
        ];

        mapBoxGL.accessToken = MAP_API_KEY;

        tryWebGL(() => {
            mapRef.current = new mapBoxGL.Map({
                container: 'map',
                style: 'mapbox://styles/mapbox/streets-v11',
                boxZoom: true,
                minZoom: 8,
                maxBounds: bounds
            });
        });

        mapRef.current?.setZoom(ROSE_CHART_INITIAL_ZOOM);
        if(position !== undefined){
            mapRef.current?.setCenter([position.long,position.lat]);
        }

        mapRef.current?.addControl(new mapBoxGL.NavigationControl({showCompass: false}));
        mapRef.current?.dragRotate.disable();
        mapRef.current?.touchZoomRotate.disableRotation();
        mapRef.current?.scrollZoom.disable();

        mapRef.current?.on("zoomend",()=>{
            if (mapRef.current) {
                const zoom =  mapRef.current?.getZoom();
                updateZoom(zoom);
            }
        });

        return () => {
            mapRef.current = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(position !== undefined && mapRef.current !== null){
            if(roseMarkerRef.current !== null){
                roseMarkerRef.current.remove();
            }
            let  el = document.createElement('div');
            ReactDOM.render(
                <PollutantRoseChart data={data} zoom={zoom + 3} relativePercent />
                , el
            );
            let marker = new mapBoxGL.Marker(el);
            marker.setLngLat({lng: position.long, lat: position.lat});
            marker.addTo(mapRef.current);
            roseMarkerRef.current = marker;
        }
    }, [data, position, zoom]);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setStyle(mapboxMapStyle);
        }
    }, [mapboxMapStyle]);


    return (
        <div>
            <div className={"no-border-map"}>
                <div className={clsx(styles.root)}  id="map"/>
            </div>
        </div>
    );
};

export default RoseChartMapView;
