import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {getVariableText} from "../../pollutantNames";
import {useTranslation} from "react-i18next";
import {sensor} from "../../../../../constants";
import {getStationSensors} from "../../../../../utils/stationUtil";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableContainer: {
        maxHeight: 400
    }
});

const OmitSensors = ({selectedStations, handleSensorChange}) => {

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <div>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("organizationPublic.station")}</TableCell>
                            <TableCell>{t("organizationPublic.sensors")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedStations.map((row) => {
                            const sensors = getStationSensors(row);
                            const filteredSensors = sensors.filter(item => {
                                if (item === sensor.rh && sensors.includes(sensor.rh_meteo)) return false;
                                return !(item === sensor.temperature && sensors.includes(sensor.temperature_meteo));
                            });
                            return (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={filteredSensors.filter(item => !row.omitSensors.includes(item))}
                                        onChange={event => {
                                            handleSensorChange(row.id, event.target.value);
                                        }}
                                    >
                                        {filteredSensors.map((sensor) =>
                                            (<ToggleButton key={sensor} value={sensor}>
                                                {getVariableText(sensor)}
                                            </ToggleButton>))
                                        };
                                    </ToggleButtonGroup>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    );
};

export default OmitSensors;
