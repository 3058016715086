import _ from "lodash";
import {getVariableText} from "../../../pollutantNames";


/**
 *
 * @param startOnSunday {boolean}
 * @param weekDaysStarOnSunday {[string]}
 * @param unit {string}
 * @param hourDayOfWeek
 * @returns {Object}
 */
export const getHourDayOfWeekChartLayout = (startOnSunday, weekDaysStarOnSunday, unit, hourDayOfWeek) => {

    const yRange = getYRange(hourDayOfWeek);

    const dayNames = startOnSunday === true ? weekDaysStarOnSunday : [...weekDaysStarOnSunday.slice(1, 7), weekDaysStarOnSunday[0]];

    const dayWidth = 1 / 7;
    let weekLayout = {};

    for (let i = 1; i <= 7; i++) {
        weekLayout[i === 1 ? "xaxis" : `xaxis${i}`] = {
            anchor: `y${i}`,
            domain: [i * dayWidth - dayWidth, i * dayWidth],
            range: [0, 24],
            showgrid: true,
            side: "top",
            mirror: true,
            ticklen: 8,
            title: {
                text: dayNames[i - 1],
            },
            linecolor: '#000000',
            tickmode: "array",
            tickvals: [0, 6, 12, 18],
            autotick: false,
            fixedrange: true
        };
        weekLayout[i === 1 ? "yaxis" : `yaxis${i}`] = {
            anchor: `x${i}`,
            showticklabels: i === 1,
            title: {text: i === 1 ? unit : "", standoff: 10},
            zerolinecolor: '#ffffff',
            range: yRange,
            domain: [0.6, 1],
            showgrid: true,
            ticklen: i === 1 ? 8 : 0,
            mirror: i === 7 ? 'ticks' : false,
            showline: true,
            linecolor: '#000000',
            linewidth: 1,
            fixedrange: true,
            zeroline:false
        };
    }
    return weekLayout;
};

/**
 *
 * @param pollutant {string}
 * @param unit {string}
 * @param units {Object}
 * @param type {"min"|"mean"|"max"|"p25"|"p75"}
 * @param dayData {{data:{Object}}}
 * @param chartIndex {Number}
 * @returns {{[p: string]: *, mode: string, hoverlabel: {namelength: number}, fillcolor: string, marker: {color: (string)}, name: string, fill: (string), type: string, hovertemplate: string}}
 */
export const getWeekDaySeries = (pollutant, unit, units, type, dayData, chartIndex) => {


    const chartData = {x: dayData.map(item => item.x), y: dayData.map(item => item[type])};

    const colorValue = type === "mean" ? "#1a23d0" : "#8d93f8";
    const yaxis = `y${chartIndex.toString()}`;
    const xaxis = `x${chartIndex.toString()}`;

    return {
        ...chartData,
        yaxis,
        xaxis,
        mode: "lines",
        fill: type === "min" || type === "mean" ? "none" : "tonexty",
        fillcolor: type === "p75" ? "rgba(26, 35, 208, 0.5)" : "rgba(26, 35, 208, 0.2)",
        line: {color: type === "mean" ? colorValue: "transparent" },
        type: 'scatter',
        marker: {color: colorValue},
        hoverlabel: {namelength: 0},
        hovertemplate: `<b>${getVariableText(pollutant, units)} ${type}</b>: %{y} ${unit}`
    };
};

export const getHourDayOfWeekData = (pollutant, unit, units, pollutantWeekData) => {

    const range = Array.from({length: 7}, (_, i) => i);

    let weekChartTempSeries = range.map((dayOfWeekData, dayIndex) => {
        const chartIndex = dayIndex + 1;
        const data = pollutantWeekData.find(item => item.day === dayIndex)?.data ??
            Array.from({length: 24}, () => null).map(() => ({min: null, max: null, mean: null, p25:null, p75:null}));

        const nextSeries = pollutantWeekData.find(item => item.day === (dayIndex !== 6 ? dayIndex + 1 : 0))?.data;
        if (nextSeries) {
            data.push({...nextSeries[0], x: 24});
        } else {
            data.push({min: null, max: null, mean: null, p25:null, p75:null, x: 24});
        }

        const mean = getWeekDaySeries(pollutant, unit, units, "mean", data, chartIndex);

        const min = getWeekDaySeries(pollutant, unit, units, "min", data, chartIndex);

        const max = getWeekDaySeries(pollutant, unit, units, "max", data, chartIndex);

        const p25 = getWeekDaySeries(pollutant, unit, units, "p25", data, chartIndex);

        const p75 = getWeekDaySeries(pollutant, unit, units, "p75", data, chartIndex);

        return [mean, min, p25, p75, max];
    });
    return _.flattenDeep(weekChartTempSeries);
};

const getYRange = (data) => {

    let minValue = Number.MAX_VALUE;
    let maxValue = -Number.MAX_VALUE;

    data.forEach(item => item.data.forEach(item => {
        if (item.min < minValue) minValue = item.min;
        if (item.max > maxValue) maxValue = item.max;
    }));

    return [minValue, maxValue];
};


