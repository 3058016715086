import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {compareStationsColors} from "../../compare/CompareScreen";
import {getVariableText} from "../../pollutantNames";
import {unitsMap} from "../../unitsNames";
import {getPollutantRange} from "../../../../../utils/chartAxeRangeUtil";
import ChartView from "../../../../common/chart/ChartView";
import {usePeriodContext} from "../common/PeriodContext";
import {UTCToLocalDate} from "../common/functions";
import {periodTypeColor, sensor, unit} from "../../../../../constants";
import {fakePlotlyDate} from "../../../../../utils/dateUtil";

const getAxeVisibilityConfig = (visiblePollutants) => {
    const units = [...new Set(visiblePollutants.map(item => item.units))];
    const pressureData = visiblePollutants.filter((item) => item.units === "hPa" && item.visible === true);

    const y1Visible = !!pressureData.find((item) => item.y.find((value) => value !== null && !Number.isNaN(value)));
    const y2Visible = units.includes("percentage") || units.includes("g-m3");
    const y3Visible = units.includes("ug-m3") || units.includes("ppb") || units.includes("IAQ");
    const y4Visible = units.includes("mg-m3") || units.includes("ppm");
    const y5Visible = units.includes("celsius") || units.includes("fahrenheit");

    return { y2Visible, y5Visible, y3Visible, y4Visible, y1Visible };
};

const initialPlotLayout = {
    legend: {x: -0.25, y: 0.9},
    yaxis: {
        overlaying: "y6",
        title: "hPa",
        zeroline: false
    },
    yaxis2: {
        overlaying: "y6",
        title: "RH %",
        range: [0, 100]
    },
    yaxis3: {
        overlaying: "y6",
        title: 'µg/m³',
        rangemode: "tozero"
    },
    yaxis4: {
        overlaying: "y6",
        title: 'mg/m³',
        rangemode: "tozero"
    },
    yaxis5: {
        overlaying: "y6",
        title: "ºC",
        zeroline: false
    },
    yaxis6: {
        range: [0, 1],
        domain: [0, 1],
        showgrid: false,
        showticklabels: false,
        zeroline: false
    },
    yaxis7: {
        range: [0, 1],
        domain: [0, 0],
        showgrid: false,
        showticklabels: false,
        zeroline: false
    },
    xaxis: {showgrid: false},
    hovermode: "x unified",
    selectdirection: "h",
    margin: {l: 50, r: 50, t: 0, b: 20},
};

const onLegendDoubleClick = () => {
    return false;
};

const PeriodChart = ({rawData, selectedSensor, periods, addedPeriods, onHover, timeZone}) => {
    const initialState = {
        y4Range: [],
        data: [],
        hiddenVariables: [],
        error: "",
        plotLayout: initialPlotLayout,
        y1Visible: true,
        y2Visible: true,
        y3Visible: true,
        y4Visible: true,
        y5Visible: true
    };

    const[{data, y4Range, plotLayout, y1Visible, y2Visible, y3Visible, y4Visible, y5Visible}, updateState] =
        useState(initialState);

    const {units} = useSelector(state => state.auth);
    const [periodShapes, setPeriodShapes] = useState([]);
    const {selectedStation} = usePeriodContext();
    const selectedStationData = useSelector(state => {
        return state.dashboardUI.stations.find(station => station.id === selectedStation);
    });

    useEffect(() => {
        updateState(state => ({
            ...state, plotLayout: {
                ...initialPlotLayout,
                yaxis4: units.pollutants === "eu" ? {...initialPlotLayout.yaxis4} : {
                    overlaying: 'y3',
                    title: 'ppm'
                },
                yaxis5: units.temperature === "celsius" ? {...initialPlotLayout.yaxis5} : {
                    title: "ºF",
                }
            }
        }));
    }, [units.pollutants, units.temperature, selectedSensor]);

    useEffect(() => {
        updateState(state => ({
            ...state, plotLayout: {
                ...initialPlotLayout,
                yaxis6: (addedPeriods) ? {
                    ...initialPlotLayout.yaxis6,
                    domain: [0.25, 1]
                } : {...initialPlotLayout.yaxis6},
                yaxis7: (addedPeriods) ? {
                    ...initialPlotLayout.yaxis7,
                    domain: [0, 0.1]
                } : {...initialPlotLayout.yaxis7}
            }
        }));
    }, [addedPeriods]);

    useEffect(() => {
        const newPeriodShapes = periods.map(period => ({
            type: 'rect',
            xref: 'x',
            yref: 'y6',
            x0: fakePlotlyDate(period.time, timeZone),
            y0: 0,
            x1: fakePlotlyDate(period.endTime, timeZone),
            y1: 1,
            fillcolor: `${periodTypeColor[period.type]}55`,
            layer: 'below',
            line: {
                width: 1,
                color: `${periodTypeColor[period.type]}80`
            }
        }));
        addedPeriods?.forEach((period) => {
            newPeriodShapes.push({
                type: 'rect',
                xref: 'x',
                yref: 'y7',
                x0: fakePlotlyDate(period.time, timeZone),
                y0: 0,
                x1: fakePlotlyDate(period.endTime, timeZone),
                y1: 1,
                fillcolor: `${periodTypeColor[period.type]}AA`,
                layer: 'below',
                line: {
                    width: 0.5,
                    color: `#00000080`
                }
            });
        });
        setPeriodShapes(newPeriodShapes);
    }, [periods, addedPeriods, timeZone]);

    const getY3Title = () => selectedSensor === sensor.voc_iaq ? unitsMap.get(unit.iaq) :
        units.pollutants === "eu" ? initialPlotLayout.yaxis3.title :
            (selectedSensor === sensor.pm1 || selectedSensor === sensor.pm2p5 || selectedSensor === sensor.pm4 ||
                selectedSensor === sensor.pm10)
                ? unitsMap.get(unit.ug_m3) : unitsMap.get(unit.ppb);

    const getChartData = useCallback((serverData, pollutant) => {
        const currentData = serverData || rawData;
        const currentPollutant = pollutant || selectedSensor;
        const dataArray = Object.entries(currentData);
        const output = [];

        if (dataArray.length && selectedStation) {
            let variablesListToShow = dataArray.filter(pollutantItem => pollutantItem[0] === currentPollutant);
            let currentStationChartInfo = [];
            variablesListToShow.forEach((aux3) => {
                let varUnit = aux3[1].units;
                let stationName = selectedStationData?.alias;
                currentStationChartInfo.push({
                    visible: true,
                    stationId: selectedStation,
                    marker: {color: compareStationsColors[0]},
                    legendgroup: `group${selectedStation}`,
                    name: stationName,
                    ...aux3[1],
                    type: 'scatter',
                    showlegend: false,
                    x: aux3[1].x.map(xValue => UTCToLocalDate(xValue)),
                    y: aux3[1].y,
                    yaxis: varUnit === "celsius" || varUnit === "fahrenheit" ? 'y5' :
                        ["percentage", "g-m3"].includes(varUnit) ? 'y2' : ["ug-m3", "ppb", "IAQ"].includes(varUnit) ? 'y3' :
                            varUnit === "mg-m3" || varUnit === "ppm" ? 'y4' : varUnit === "hPa" ? 'y1' : "error",

                    hovertemplate: `<b>${stationName} - ${getVariableText(aux3[0], units)}</b> %{y} ${unitsMap.get(varUnit)}`,
                    hoverlabel: {namelength: 0},
                });
            });
            output.push(...currentStationChartInfo);
        }
        return output;
    }, [rawData, selectedStation, selectedSensor, selectedStationData, units]);

    useEffect(() => {
        updateState(state => {
            return {
                ...state,
                data: [],
                y4Range: []
            };
        });

        const chartData = getChartData(null, selectedSensor);
        if (chartData.length > 0) {
            updateState(state => {
                return {...state, data: chartData, y4Range: getPollutantRange(chartData, units),
                    ...getAxeVisibilityConfig(chartData)};
            });
        } else {
            updateState(state => {
                return {...state, data: chartData};
            });
        }
    }, [rawData, getChartData, selectedSensor, units]);

    const plotConfig = {
        displaylogo: false,
        displayModeBar: true,
        modeBarButtons: [['zoom2d', 'pan2d', 'zoomIn2d', 'zoomOut2d', 'resetScale2d']],
    };

    return (
        <ChartView
            layout={{
                ...plotLayout,
                yaxis: {...plotLayout.yaxis, visible: y1Visible, fixedrange: true},
                yaxis2: {...plotLayout.yaxis2, visible: y2Visible, fixedrange: true},
                yaxis3: {...plotLayout.yaxis3, title: getY3Title(), visible: y3Visible, fixedrange: true},
                yaxis4: y4Range.length === 0 ? {...plotLayout.yaxis4, visible: y4Visible, fixedrange: true} :
                    {...plotLayout.yaxis4, visible: y4Visible, range: y4Range, fixedrange: true},
                yaxis5: {...plotLayout.yaxis5, visible: y5Visible, fixedrange: true},
                shapes: periodShapes,
                dragmode: 'x'
            }}
            data={data}
            onLegendDoubleClick={onLegendDoubleClick}
            config={plotConfig}
            onHover={onHover}
        />
    );
};

export default PeriodChart;
