import {webClient} from "../../config/axios";

export const pollutantTrendRequest = ({stationId, ...params}) => {
    const url = `/station-data/${stationId}/pollutant-trend`;

    return webClient.get(url, {params}).then( result => result.data).catch(err => {
        if (err.response?.status === 404)
            return undefined;
        throw(err);
    });
};


