import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Card,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {humidityType, indexTypes, sensor, unit} from "../../../../../constants";
import {shallowEqual} from "react-redux";
import HelpPopup from "../../../../common/HelpPopup";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {updateUnitsRequest} from "../../../../../requests/organization/organizationRequests";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import CardViewLoading from "../../../../common/chart/ChartLoading";
import DataNotFound from "../../../../common/DataNotFound";
import {useTranslation} from "react-i18next";
import useAuth from "../../../../../hooks/useAuth";
import useMessage from "../../../../../hooks/useMessage";
import useRequest from "../../../../../hooks/useRequest";
import {WRITE_ORGANIZATION_CONFIG} from "../members/UserPermissions";
import SaveButton from "../../../../common/buttons/SaveButton";
import {unitsMap} from "../../unitsNames";
import {pollutantNames} from "../../pollutantNames";
import {getAqiIndexNameAndRegionText} from "../../../../../utils/aqiUtil";

const useStyles = makeStyles({
    saveButton:{
        color:'#ffffff',
    },
    title:{
        fontWeight:"bold"
    },
    helpButton:{
        color:"black"
    }
});

const OrganizationUnits = ({className, unitsConfig}) => {

    const { t } = useTranslation();
    const { hasPermission } = useAuth();
    const { setError, setSuccess } = useMessage();
    const [{organizationUnits, saving}, updateState] = useState({
        organizationUnits: {}, saving: false
    });
    const [loading, setLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [loadedConfig, setLoadedConfig] = useState(null);
    const isMounted = useMountComponent();
    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();
    const {handleErrorResponse} = useRequest();

    const classes = useStyles();

    const resetData = useCallback(() => {
        updateState(state => ({...state, organizationUnits: unitsConfig}));
    }, [unitsConfig]);

    useEffect(() => {
        if (unitsConfig) {
            setLoading(false);
            setLoadingError(false);
            resetData();
            setLoadedConfig(unitsConfig);
        } else {
            setLoading(unitsConfig !== null);
            setLoadingError(unitsConfig === null);
        }
    }, [unitsConfig, resetData]);

    const handleIndexChange = (event) =>{
        updateState(state =>(
            {...state,organizationUnits:{...state.organizationUnits,index:event.target.value}}));
    };

    const handleWindSpeedChange = (event) =>{
        updateState(state =>(
            {...state,organizationUnits:{...state.organizationUnits,windSpeed:event.target.value}}));
    };

    const handleTempChange = (event) =>{
        updateState(state =>(
            {...state,organizationUnits:{...state.organizationUnits,temperature:event.target.value}}));
    };

    const handlePollutantsChange = (event) =>{
        updateState(state =>(
            {...state,organizationUnits:{...state.organizationUnits,pollutants:event.target.value}}));
    };

    const handleHumidityChange = (event) => {
        updateState(state => (
            {...state, organizationUnits: {...state.organizationUnits, humidity: event.target.value}}));
    };

    const handleSave = useCallback(() => {
        updateState(state => ({...state, saving: true}));
        updateUnitsRequest({...organizationUnits },(error,data) =>{
            if (!isMounted.current) {return;}

            if (!error) {
                updateState(state => ({...state, saving: false}));
                setSuccess("organizationUnit.units_updated");
                setLoadedConfig(organizationUnits);
            } else {
                resetData();
                updateState(state => ({...state, saving: false}));
                handleErrorResponse(data, response => {
                    setLoading(false);
                    setError(response,
                        false,
                        `${t("error")} ${response?.status ?? ''},
                        ${t("organizationUnit.updating_organization_units")}`);
                });
            }
        });
    }, [handleErrorResponse, isMounted, organizationUnits, resetData, setError, setSuccess, t]);

    const hasChanges = useMemo(() => {
        return !shallowEqual(organizationUnits, loadedConfig);
    }, [loadedConfig, organizationUnits]);

    return (
        <Card className={className}>
            {loading && <CardViewLoading/>}
            {loadingError && <DataNotFound/>}
            {!loading && !loadingError && (
                <Grid container direction={"row"} spacing={2}>
                    <Grid item container xs={12}>
                        <Typography className={classes.title} variant={"h4"}>{t("organizationUnit.title")}</Typography>
                        <IconButton className={classes.helpButton} aria-label="help"
                                    onClick={e => setAnchorEl(e.currentTarget)}>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                            <InputLabel >{t("units.pollutant_index")}</InputLabel>
                            <Select
                                disabled={saving || !hasPermission(WRITE_ORGANIZATION_CONFIG)}
                                onChange={handleIndexChange}
                                label={t("units.pollutant_index")}
                                value={organizationUnits.index || ""}
                                data-testid = "profile-pollutant-index"
                                labelId="demo-simple-select-label"
                                id="profile-selector-pollutant"
                            >
                                {Object.values(indexTypes).map(item =>
                                    <MenuItem key={item} value={item}>{getAqiIndexNameAndRegionText(item, t)}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                            <InputLabel>{t("units.concentration_units")}</InputLabel>
                            <Select
                                disabled={saving || !hasPermission(WRITE_ORGANIZATION_CONFIG)}
                                label={t("units.concentration_units")}
                                onChange={handlePollutantsChange}
                                data-testid = "profile-concentration"
                                value={organizationUnits.pollutants || ""}
                                labelId="demo-simple-select-label"
                                id="profile-selector-concentration"
                            >
                                <MenuItem value={"eu"}>µg/m³ - mg/m³</MenuItem>
                                <MenuItem value={"usa"}>{t("units.ppm_ppb")}</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                            <InputLabel>{t("units.temperature_units")}</InputLabel>
                            <Select
                                disabled={saving || !hasPermission(WRITE_ORGANIZATION_CONFIG)}
                                label={t("units.temperature_units")}
                                onChange={handleTempChange}
                                data-testid = "profile-temperature"
                                value={organizationUnits.temperature || ""}
                                labelId="demo-simple-select-label"
                                id="profile-selector-temperature"
                            >
                                <MenuItem value={unit.celsius}>Celsius (ºC)</MenuItem>
                                <MenuItem value={unit.fahrenheit}>Fahrenheit (ºF)</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                            <InputLabel>{t("units.wind_speed_unit")}</InputLabel>
                            <Select
                                disabled={saving || !hasPermission(WRITE_ORGANIZATION_CONFIG)}
                                label={t("units.wind_speed_unit")}
                                onChange={handleWindSpeedChange}
                                data-testid = "profile-wind"
                                value={organizationUnits.windSpeed || ""}
                                labelId="demo-simple-select-label"
                                id="profile-selector-wind"
                            >
                                <MenuItem value={"mps"}>{t("units.meter_per_second")}</MenuItem>
                                <MenuItem value={"mph"}>{t("units.mile_per_hour")}</MenuItem>
                                <MenuItem value={"knots"}>{t("units.knots")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" fullWidth className={classes.formControl} margin={"normal"}>
                            <InputLabel>{t("units.humidity_type")}</InputLabel>
                            <Select
                                disabled={saving || !hasPermission(WRITE_ORGANIZATION_CONFIG)}
                                label={t("units.humidity_type")}
                                onChange={handleHumidityChange}
                                data-testid = "profile-humidity"
                                value={organizationUnits.humidity || ""}
                                labelId="demo-simple-select-label"
                                id="profile-selector-humidity"
                            >
                                <MenuItem value={humidityType.relative}>
                                    {`${t("units.relative")} (${pollutantNames.get(sensor.rh)} ${unitsMap.get(unit.percentage)})`}
                                </MenuItem>
                                <MenuItem value={humidityType.absolute}>
                                    {`${t("units.absolute")} (${pollutantNames.get(sensor.ah)} ${unitsMap.get(unit.g_m3)})`}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {hasPermission(WRITE_ORGANIZATION_CONFIG) && <Grid item md={8} lg={9}> </Grid>}
                    {hasPermission(WRITE_ORGANIZATION_CONFIG) &&
                    <Grid item xs={12} md={4} lg={3}>
                        <SaveButton className={classes.saveButton}
                                    data-testid="organzation-units-save-button"
                                    disabled={saving || !hasChanges} saving={saving} fullWidth
                                    onSave={handleSave} />
                    </Grid>}
                </Grid>
            )}
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose} message={t("helpMessages.en_organization_units")}/>
        </Card>
    );
};

export default OrganizationUnits;
