import {timeZoneOptions} from "../../../../../constants";

export const chartDomains = {
    first: [0.75, 1],
    second: [0.5, 0.69],
    third: [0.25, 0.44],
    fourth: [0, 0.19],
    firstExpanded: [0.6, 1],
    secondExpanded: [0.3, 0.54],
    thirdExpanded: [0, 0.24],
    firstMoreExpanded: [0.42, 1],
    secondMoreExpanded: [0, 0.36],
    secondFifty: [0.53, 1],
    thirdFifty: [0, 0.47],
    full: [0, 1]
};

export const initialPlotLayout = {
    showlegend: false,
    yaxis5: {
        title: 'mg/m³',
        rangemode: "nonnegative",
        overlaying: 'y4'
    },
    yaxis4: {
        title: 'µg/m³',
        rangemode: "nonnegative"
    },
    yaxis3: {
        title: "RH %",
        overlaying: 'y2'
    },
    yaxis2: {
        title: "ºC"
    },
    yaxis: {
        title: "hPa",
        overlaying: 'y8'
    },
    yaxis6: {
        title: "W/m²",
        range: [0, 1100],
        side: "left",
        domain: chartDomains.fourth
    },
    yaxis7: {
        title: "UV Index",
        range: [0, 11],
        side: "right",
        overlaying: 'y6'
    },
    yaxis8: {
        title: "mm/h",
        side: "right",
        rangemode: "nonnegative"
    },
    xaxis: {
        showgrid: false,
        type: "date",
        anchor: "y6"
    },
    hovermode: "x unified",
    selectdirection: "h",
    margin: {t: 0}
};

export const plotConfig = {
    modeBarButtonsToRemove: ["select2d", "lasso2d",
        "toggleHover", "sendDataToCloud", "toggleSpikelines",
    ],
    displaylogo: false
};

export const presetVariables = ['temperature', 'relativeHumidity', 'pressure', 'equivalentPressure'];

export const initialState = {
    data: [],
    rawData: [],
    localData: [],
    error: "",
    loading: true,
    y1Visible: true,
    y2Visible: true,
    y3Visible: true,
    y4Visible: true,
    y5Visible: true,
    y6Visible: true,
    y7Visible: true,
    y8Visible: true,
    y3Side: "right",
    y4Side: "left",
    y5Side: "right",
    y7Side: "right",
    y8Side: "right",
    y5Range: [],
    y1Domain: chartDomains.third,
    y2Domain: chartDomains.second,
    y3Domain: chartDomains.second,
    y4Domain: chartDomains.first,
    y5Domain: chartDomains.first,
    y6Domain: chartDomains.fourth,
    y7Domain: chartDomains.fourth,
    y8Domain: chartDomains.third,
    timeZone: timeZoneOptions.StationLocalTime
};
