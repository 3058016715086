import {webClient} from '../../config/axios';
import {handleError} from "../handleRequestError";

export const verifyOpt = (body) => {
    return webClient.post('/auth/otp', body, {
        headers: {
            "Content-Type": "application/json"
        }
    });
};

export const keepAliveRequest = ()=>{
    return webClient.post('/user/keep-alive',{});
};

export const resendOpt = (body) => {
    return webClient.post('/auth/resend-otp', body, {
        headers: {
            "Content-Type": "application/json"
        }
    });
};

export const otpRequest = (body) => {
    return webClient.post('/auth/login', body, {
        headers: {
            "Content-Type": "application/json"
        }
    });
};

export const forgotRequest = (body,callback) => {
    webClient.post('/auth/reset',body,{
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const forgotChangePasswordRequest = (body,callback) =>{
    webClient.post('/auth/reset/pass',body,{
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const updateExpiredPasswordRequest = (body) => {
    return webClient.post('/auth/pass', body, {
        headers: {
            "Content-Type": "application/json"
        }
    });
};

export let logoutRequest = (callback) => {
    webClient.get('/user/logout')
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        });
};

export const checkTokenRequest = () => {
    return webClient.get('/check-token');
};
