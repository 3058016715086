import React, {useCallback, useEffect, useMemo, useState} from 'react';
import DataGrid from "../../../../common/dataGrid/DataGridComponent";
import {
    getDataTableFormattedTime} from "../../../../../utils/getFormattedDateTime";
import {Link} from "@mui/material";
import {useTranslation} from "react-i18next";
import {usePeriodContext} from "../common/PeriodContext";
import HistoryDetailPopup from "./HistoryDetailPopup";
import {formatDateRange, localToUTCDate} from "../common/functions";
import {getVariableText} from "../../pollutantNames";
import {makeStyles} from "@mui/styles";
import moment from "moment-timezone";
import {useSelector} from "react-redux";
import {timeZoneOptions} from "../../../../../constants";
import useUnits from "../../../../../hooks/useUnits";

const useStyles = makeStyles({
    variablesCell: {
        whiteSpace: "normal"
    },
    variableLink: {
        cursor: "pointer"
    }
});

const PeriodList = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {units} = useUnits();
    const [rows, updateRows] = useState([]);
    const [detailSelectedRow, setDetailSelectedRow] = useState(null);
    const {selectedStation, validationHistory, setNewSelectedPeriod, historyLoading, setSelectedPositionDate, setScrollToTableTriggered} = usePeriodContext();
    const [sortModel, setSortModel] = useState([{
        field: 'created',
        sort: 'desc'
    }]);

    const selectedStationData = useSelector(state => {
        return state.dashboardUI.stations.find(station => station.id === selectedStation);
    });
    const dataTimeZone = useSelector(state => state.auth.timeZone);
    const timeZone =
        (dataTimeZone === timeZoneOptions.StationLocalTime && selectedStationData.position?.locationInfo?.timeZone)
        || timeZoneOptions.UTC;

    const onOpenDetailPressed = useCallback((row) => {
        setDetailSelectedRow(row);
    }, []);

    const onDetailClose = useCallback(() => {
        setDetailSelectedRow(null);
    }, []);

    const onPeriodSelected = useCallback((row) => {
        const startDate = localToUTCDate(row.time);
        const endDate = localToUTCDate(row.endTime);
        setNewSelectedPeriod({startDate, endDate});
    }, [setNewSelectedPeriod]);

    const monthNames = useMemo(() => [
        t("validation.monthNames.january"),
        t("validation.monthNames.february"),
        t("validation.monthNames.march"),
        t("validation.monthNames.april"),
        t("validation.monthNames.may"),
        t("validation.monthNames.june"),
        t("validation.monthNames.july"),
        t("validation.monthNames.august"),
        t("validation.monthNames.september"),
        t("validation.monthNames.october"),
        t("validation.monthNames.november"),
        t("validation.monthNames.december")
    ], [t]);

    const handleNavigateToDate = useCallback((date)=>{
        const startDate = localToUTCDate(detailSelectedRow.time);
        const endDate = localToUTCDate(detailSelectedRow.endTime);
        setNewSelectedPeriod({startDate, endDate});
        setSelectedPositionDate(date);
        setScrollToTableTriggered(true);
        setDetailSelectedRow(null);
    },[detailSelectedRow?.endTime, detailSelectedRow?.time, setNewSelectedPeriod, setScrollToTableTriggered, setSelectedPositionDate]);

    const columns = useMemo(() => {
        return [
            {
                field: 'id',
                hide: true,
            },
            {
                field: 'created',
                headerName: t("common.creation_date"),
                width: 170,
                renderCell: (params) => {
                    return getDataTableFormattedTime(monthNames, params.value);
                }
            },
            {
                field: 'time',
                headerName: t("periodSelector.period"),
                width: 220,
                renderCell: (params) => {
                    const onClick = () => onPeriodSelected(params.row);
                    const endDate = moment(params.row.endTime).tz(timeZone);
                    // Disable link if period is more than one month
                    const isMoreThanOneMonth = moment(params.row.time).diff(endDate, 'months') > 1;
                    endDate.subtract(1, "day");
                    if (isMoreThanOneMonth) {
                        return formatDateRange(params.row.time, endDate.toDate(), timeZone);
                    }
                    return (
                        <Link onClick={onClick} className={classes.variableLink}>
                            {formatDateRange(params.row.time, endDate.toDate(), timeZone)}
                        </Link>
                    );
                }
            },
            {
                field: 'user',
                headerName: t("validation.user"),
                width: 150
            },
            {
                field: 'affectedSensors',
                headerName: t("downloadScreen.detailPopUp.variables"),
                width: 250,
                sortable: false,
                renderCell: (params) => (
                    <p className={classes.variablesCell}>
                        {params.value?.map((sensor, index) => {
                            const onClick = () => onOpenDetailPressed({...params.row, sensor});
                            return <>
                                {index > 0 && ", "}
                                <Link key={index} className={classes.variableLink} onClick={onClick}>
                                    {getVariableText(sensor, units)}
                                </Link>
                            </>;
                        })}
                    </p>
                )
            }
        ];
    }, [t, monthNames, timeZone, classes.variableLink, classes.variablesCell, onPeriodSelected, units, onOpenDetailPressed]);

    useEffect(() => {
        updateRows(validationHistory.map((period) => {
            const startDate = moment(period.time).tz(timeZone).startOf("day");
            const endTime = moment(period.endTime).tz(timeZone);
            const endDate = moment(endTime).startOf("day");
            if (!endTime.isSame(endDate) || startDate.isSame(endDate)) {
                endDate.add(1, "day");
            }
            return {
                ...period,
                created: new Date(period.created),
                time: startDate.toDate(),
                endTime: endDate.toDate()
            };
        }));
    }, [validationHistory, timeZone]);


    return (
        <>
            <DataGrid
                style={{minHeight: "300px", width: '100%'}}
                rows={rows}
                columns={columns}
                loading={historyLoading}
                pageSize={7}
                rowsPerPageOptions={[5]}
                checkboxSelection={false}
                disableSelectionOnClick
                sortModel={sortModel}
                onSortModelChange={setSortModel}
            />
            <HistoryDetailPopup row={detailSelectedRow} onClose={onDetailClose}  navigateToDate={handleNavigateToDate}/>
        </>
    );
};

export default PeriodList;
