import React, {useCallback, useEffect, useState} from 'react';
import {Card, Grid, Stack, TextField, Typography} from "@mui/material";
import RdThresholdTable from "./RdThresholdTable";
import {rdThresholdRequest} from "../../../../../requests/analytics/rdThresholdRequest";
import {useSelector} from "react-redux";
import HelpPopup from "../../../../common/HelpPopup";
import CardTitle from "../../../../common/card_views/CardTitle";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import CardLoading from "../../../../common/card_views/CardLoading";
import {useTranslation} from "react-i18next";
import useMessage from "../../../../../hooks/useMessage";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {getDateFnsLocale} from "../../../../../utils/dateUtil";
import DataNotFound from "../../../../common/DataNotFound";
import {mapStationsToIdAlias} from "../../../../../utils/stationUtil";
import {isEqual} from "lodash";
import useRequest from "../../../../../hooks/useRequest";

const RdThresholdCardView = ({className}) => {

    const {anchorEl, setAnchorEl, handleHelpClose} = useAnchorEl();

    const {t} = useTranslation();
    const {handleErrorResponse} = useRequest();
    const {setError} = useMessage();
    const [{data, noLocation, loading}, updateState] = useState({data: null, noLocation: [], loading: true});
    const selectCompareStations = useSelector(state => mapStationsToIdAlias(state.dashboardUI.selectCompareStations), isEqual);
    const {units, legalThreshold} = useSelector(state => state.auth);
    const [date, setDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [dateOpen, setDateOpen] = useState(false);

    const isMounted = useMountComponent();

    const fetchRdThresholdData = useCallback(async () => {
        updateState({data: null, noLocation: [], loading: true});
        const year = date;
        if (year) {
            try {
                if (selectCompareStations?.length) {
                    const rdData = await Promise.all(selectCompareStations.map(async station => (
                        rdThresholdRequest(units.pollutants, station.id, year)
                    )));
                    const newData = [];
                    const noLocation = [];
                    rdData.forEach((stationData, index) => {
                        if (!stationData) {
                            noLocation.push(selectCompareStations[index].alias);
                            newData.push({station: selectCompareStations[index]}); // Add empty object to keep the order
                        } else if (Object.keys(stationData).length) {
                            newData.push({
                                station: selectCompareStations[index],
                                ...stationData
                            });
                        }
                    });
                    if (isMounted.current) {
                        updateState({data: newData, noLocation, loading: false});
                    } else {
                        updateState({data: null, noLocation, loading: false});
                    }
                }
            } catch (err) {
                const {request} = err;
                handleErrorResponse(request, () => {
                    setError(t("analyticScreen.rdThreshold.could_not_update_rd_table"));
                });
                updateState({data: null, loading: false, noLocation: []});
            }
        }
    }, [handleErrorResponse, setError, isMounted, selectCompareStations, units.pollutants, date, t]);

    useEffect(() => {
        fetchRdThresholdData();
    }, [fetchRdThresholdData]);

    return (
        <Card className={className}>
            {!loading &&
                <Grid container direction={"row"}>
                    <Grid container item xs={12} md={9} alignItems={"center"} alignContent={"center"}>
                        <CardTitle title={t("analyticScreen.rdThreshold.title")} setAnchorEl={setAnchorEl}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: "100%"
                        }}>
                            <Typography variant={"subtitle2"} style={{marginRight: 15}}>
                                {t("units.year")}
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getDateFnsLocale()}>
                                <Stack spacing={3}>
                                    <DatePicker
                                        views={['year']}
                                        //label="Year"
                                        value={date}
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                        }}
                                        minDate={new Date(2000, 0, 1)}
                                        maxDate={new Date()}
                                        open={dateOpen}
                                        onOpen={() => setDateOpen(true)}
                                        onClose={() => setDateOpen(false)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onClick={() => setDateOpen(true)}
                                                helperText={null}
                                                size="small"
                                                style={{width: 68}}
                                                InputProps={{
                                                    endAdornment: null,
                                                }}
                                            />
                                        )}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </div>
                    </Grid>
                    {/* Add vertical margin on large screens */}
                    <Grid item xs={0} md={12} style={{marginTop: 25}}/>
                    {data ? (
                        <Grid item xs={12} style={{minHeight: 320}}>
                            {(data.length) ? (
                                <RdThresholdTable rdData={data} noLocation={noLocation}/>
                            ) : (
                                <DataNotFound/>
                            )}
                        </Grid>
                    ) : (
                        <DataNotFound message={t('chart.errorFetchingData')}/>
                    )}
                    {noLocation.length > 0 &&
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center"}}>
                            <Typography variant={"body2"}>
                                {`*${t("requestErrors.noPositionFound")}`}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            }
            {loading && <CardLoading/>}
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose}
                       message={t(`analyticScreen.rdThreshold.help_text_${legalThreshold.toLowerCase()}`)}
                       halfCard={true}/>
        </Card>
    );
};

export default RdThresholdCardView;
