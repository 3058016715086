import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DEFAULT_PERIOD, sensor} from "../../../../../constants";
import useMessage from "../../../../../hooks/useMessage";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {getDateRange} from "../../../../../utils/requestPeriodGenerator";
import {makeStyles} from "@mui/styles";
import {getVariableText} from "../../pollutantNames";
import WindRoseChart from "./WindRoseChart/WindRoseChart";
import {unitsMap} from "../../unitsNames";
import {windDataRequest} from "../../../../../requests/analytics/windDataRequest";
import CardLayout from "../../../../common/card_views/CardLayout";
import ChartLayout from "../../../../common/chart/ChartLayout";
import ChartView from "../../../../common/chart/ChartView";
import useRequest from "../../../../../hooks/useRequest";
import {getPollutantColor} from "bettairplaformutil/src/colorUtil";
import {AUTO_RESOLUTION} from "../common/ResolutionSelector";


const legendColors = [getPollutantColor(sensor.wind_speed), getPollutantColor(sensor.wind_gust)];

const useStyles = makeStyles({
    windSpeedChart: {
        "& .js-plotly-plot": {
            width: "100%",
            height: 500
        }
    }
});

const plotConfig = {
    modeBarButtonsToRemove: ["select2d", "lasso2d",
        "toggleHover", "sendDataToCloud", "toggleSpikelines",
    ],
    displaylogo: false
};

const getInitialPlotLayout = (t) => {
    return {
        legend: {x: -0.25, y: 0.9},
        yaxis2: {
            domain: [0.4, 1],
            title: t("analyticScreen.windSpeed.speed_mps"),
        },
        yaxis: {
            title: t("analyticScreen.windSpeed.degrees_uppercase"),
            range: [0, 359],
            domain: [0, 0.4],
        },
        xaxis: {
            showgrid: false,
            domain: [0.08, 1]
        },
        hovermode: "x unified",
        margin: {l: 40, r: 40, t: 0, b: 40},
    };
};

const WindSpeedCardView = ({className}) => {

    const {t} = useTranslation();
    const notFoundMessage = useMemo(() => {
        return t("dataNotFound");
    }, [t]);
    const {handleErrorResponse} = useRequest();

    const initialState = {
        data: [], roseData: null,
        visibleVariables: [0, 1],
        error: notFoundMessage,
        loading: true,
        plotLayout: getInitialPlotLayout(t),
        dataTimeZone: null
    };

    const [{data, roseData, loading, plotLayout, visibleVariables, dataTimeZone, error}, updateState] = useState(initialState);
    const {setError} = useMessage();
    const isMounted = useMountComponent();
    const selectedStation = useSelector(state => state.dashboardUI.selectedStation);
    const {units} = useSelector(state => state.auth);
    const [period, setPeriod] = useState(DEFAULT_PERIOD);
    const [selectedResolution, updateSelectedResolution] = useState(AUTO_RESOLUTION);
    const [dateRange, setDateRange] = useState(null);
    const selectedRange = useMemo(() => getDateRange(period, dateRange), [period, dateRange]);
    const [calmWindSpeed, setCalmWindSpeed] = useState(null);


    useEffect(() => {
        updateState(state => ({
            ...state, plotLayout: {
                ...getInitialPlotLayout(t),
                yaxis2: units.windSpeed === "mps" ? {...getInitialPlotLayout(t).yaxis2} :
                    units.windSpeed === "mph" ? {
                            domain: [0.4, 1],
                            title: t("analyticScreen.windSpeed.speed_mph")
                        } :
                        {
                            domain: [0.4, 1],
                            title: t("analyticScreen.windSpeed.speed_knots")
                        }
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [units.pollutants, units.temperature]);

    const classes = useStyles();

    const updateWindSpeedChart = useCallback((data) => {
        setCalmWindSpeed(data.windCalmRate);
        let dataArray = Object.entries(data.timeSeries);

        let newData = dataArray.map((value, index) => {
            let units = unitsMap.get(value[1].units);
            let y = value[1].y;
            let adaptedData = {...value[1], y};
            return {
                visible: visibleVariables.includes(index) ? true :
                    "legendonly",
                name: ` ${getVariableText(value[0])}`,
                ...adaptedData,
                type: 'scatter',
                mode: value[0] === "wdirection" || value[0] === "windGust" ? 'markers' : 'lines',
                hoverlabel: {namelength: 0},
                hovertemplate: `<b>${getVariableText(value[0])}</b>: %{y} ${units}`,
                marker: {color: legendColors[index], size: 3},
                yaxis: value[1].units === "mps" || value[1].units === "mph" || value[1].units === "knots" ?
                    'y2' : value[1].units === "degrees" ? 'y1' : "error"
            };
        });

        if (newData.length > 0) {
            const minX = newData[0].x[0];
            const maxX = newData[0].x[newData[0].x.length - 1];

            updateState(state => {
                return {
                    ...state,
                    data: newData,
                    roseData: data.roseData,
                    dataTimeZone: data.timeZone,
                    loading: false,
                    plotLayout: {
                        ...state.plotLayout,
                        xaxis: {
                            ...state.plotLayout.xaxis,
                            range: [minX, maxX]
                        }
                    }
                };
            });
        } else {
            updateState(state => {
                return {...state, data: [], roseData: data.roseData, loading: false,};
            });
        }
    }, [visibleVariables]);


    const updateData = useCallback(() => {

        updateState(state => {
            return {...state, loading: true, data: [], roseData: null, error: "", y4Range: [], dataTimeZone: null};
        });

        windDataRequest(units.windSpeed, selectedStation, selectedRange[0], selectedRange[1], selectedResolution).then((data)=>{
            if (!isMounted.current) return;
            if (data === null) {
                updateState(state => {
                    return {...state, loading: false, error: notFoundMessage, data: []};
                });
            } else {
                updateWindSpeedChart(data);
            }
        }).catch((err) => {
            if (!isMounted.current) return;
            const {request} = err;
            updateState(state => {
                return {...state, loading: false, error: t("analyticScreen.windSpeed.could_not_update_station_data")};
            });
            handleErrorResponse(request, response => {
                setError(response, false, "analyticScreen.windSpeed.could_not_update_station_data");
            });
        });

    }, [units.windSpeed, selectedStation, selectedRange, selectedResolution, isMounted, notFoundMessage, updateWindSpeedChart, t, handleErrorResponse, setError]);

    useEffect(() => {
        updateData();
    }, [updateData]);

    const handleRefresh = () => {
        updateData(null);
    };

    const onLegendClick = (event) => {
        let position = event.curveNumber;
        let newVisibleList = !visibleVariables.includes(position) ? [...visibleVariables, position] :
            visibleVariables.filter(item => item !== position);
        updateState(state => ({...state, visibleVariables: newVisibleList}));
    };

    const onLegendDoubleClick = (_) => {
        return false;
    };

    const setSelectedResolution = useCallback((event) => {
        updateSelectedResolution(event.target.value);
    }, [updateSelectedResolution]);

    return (
        <CardLayout className={className} title={t("analyticScreen.windSpeed.title")} dataTimeZone={dataTimeZone}
                    helpText={t("analyticScreen.windSpeed.en_analytics_wind_speed")}
                    refreshButtonEvent={handleRefresh} refreshButtonDisabled={loading}>
            <ChartLayout error={error} loading={loading} emptyData={data.length === 0} height={420}
                         onResolutionChange={setSelectedResolution} resolution={selectedResolution}
                         chartStyles={{"& .modebar": {left: "42%"}}} period={period} onPeriodChange={setPeriod}
                         dateRange={dateRange} onDateRangeChange={setDateRange} dataTimeZone={dataTimeZone}
                         position="top">
                <Grid container>
                    <Grid item xs={6} md={8} className={classes.windSpeedChart}>
                        <ChartView
                            layout={{...plotLayout}}
                            data={data}
                            onLegendDoubleClick={onLegendDoubleClick}
                            onLegendClick={onLegendClick}
                            config={plotConfig}
                        />
                    </Grid>
                    {roseData !== null && (
                        <Grid item xs={6} md={4} style={{paddingRight: 40}}>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <WindRoseChart data={roseData} calmWind={calmWindSpeed} />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </ChartLayout>
        </CardLayout>
    );
};

export default WindSpeedCardView;
