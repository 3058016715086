import React, {useCallback} from 'react';
import {Backdrop, Card, Divider, Grid, Typography} from "@mui/material";
import DownloadSteps from "./DownloadSteps";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import {useSelector} from "react-redux";
import validator from "validator";
import { getDateRangeIncludingEndDate} from "../../../../utils/requestPeriodGenerator";
import {useTranslation} from "react-i18next";
import {DEFAULT_POPUP_WIDTH} from "../../../../constants";
import {localToUTCDateString} from "../../../../utils/dateUtil";

export const DECIMAL_SEPARATOR={comma:"comma",dot:"dot"};

const useStyles = makeStyles({
    card:{
        paddingTop:16,
        paddingBottom:10,
    },
    tittle:{
        marginLeft:30,
        color:"#434343",
        marginBottom:16
    },
    buttons:{
        marginRight:30,
        display:"flex",
        marginTop:10,
        justifyContent:"flex-end"
    },
    divider:{
        marginBottom:32
    },
    divider2:{
        marginTop:8
    },
    steps:{
        marginRight:20,
        marginLeft:20
    },
    stepContainer:{
        margin:40
    },
    popUpContainer: {
        maxWidth: DEFAULT_POPUP_WIDTH
    },
    step1Container: {
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 10,
        maxWidth: 1000,
        justifyContent: "center"
    },
    step2Container: {
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 10,
        maxWidth: 700,
        justifyContent: "center"
    }
});

const DownloadConfigPopUp = ({open, finishDownloadSetup, cancelDownload}) => {

    const {t} = useTranslation();
    const classes = useStyles();


    const { units } = useSelector( state => state.auth );
    const [{activeStep,
        name, pollutantUnits,tempUnits, humidityUnits, index, period,dateRange,step1Valid,winSpeedUnit, groupInterval,
        includeValidation,decimalSeparator,
        selectedStations,step2Valid,
        selectedVariables,step3Valid,
        timeZoneError
    }, updateState] = React.useState(
        {activeStep:0,
            name:"",pollutantUnits:units.pollutants,
            tempUnits:units.temperature,
            humidityUnits: units.humidity,
            period:30,dateRange:null,step1Valid:false,
            groupInterval:5,
            includeValidation: 0,
            decimalSeparator:DECIMAL_SEPARATOR.dot,
            winSpeedUnit:units.windSpeed
            ,selectedStations:[],step2Valid:false,
            selectedVariables:[],step3Valid:false,
            timeZoneError:false,
            index:units.index
        });

    //Step 1 callbacks
    const nameCallback = useCallback((event)=>{
        let value = event.target.value;
        let step1Valid = !validator.isEmpty(value) && !(value.includes(`/`) || value.includes(`\\`) || value.startsWith("."));
        updateState(state=>({...state,name:value,step1Valid}));
    },[]);

    const groupIntervalCallback = useCallback((event)=>{
        updateState(state=>({...state,groupInterval: event.target.value}));
    },[]);

    const includeValidationCallback = useCallback((event)=>{
        updateState(state=>({...state, includeValidation: event.target.value}));
    },[]);

    const decimalSeparatorCallback = useCallback((event)=>{
        updateState(state=>({...state,decimalSeparator: event.target.value}));
    },[]);

    const windSpeedCallback = useCallback((event)=>{
        updateState(state => ({...state,winSpeedUnit: event.target.value }));
    },[]);

    const pollutantCallback = useCallback((event)=>{
        updateState(state => ({...state,pollutantUnits: event.target.value }));
    },[]);

    const tempCallback = useCallback((event)=>{
        updateState(state => ({...state,tempUnits: event.target.value }));
    },[]);

    const humidityCallback = useCallback((event) => {
        updateState(state => ({...state,humidityUnits: event.target.value}));
    }, []);

    const updateDateCallback = useCallback((period,dateRange)=>{
        updateState(state => ({...state,period,dateRange}));
    },[]);

    const indexCallback = useCallback((event) => {
        updateState(state => ({...state, index: event.target.value}));
    }, []);

    //Step 2 callbacks
    const selectedStationsCallback = useCallback((stations)=>{
        const timeZoneError = stations.filter(item => !item.timeZone).length > 0;
        updateState(state=>({...state,selectedStations: stations, step2Valid: stations.length>0, timeZoneError}));
    },[]);

    //Step 3 callbacks
    const selectedVariablesCallback = useCallback((variables)=>{
        updateState(state=>({...state,selectedVariables: variables, step3Valid:variables.length>0 }));
    },[]);


    const handleFirstButton = ()=>{
        if (activeStep===0){
            cancelDownload();
        }else {
            updateState(state => ({...state,activeStep: activeStep-1}));
        }
    };

    const handleSecondButton = ()=>{
        if (activeStep<=1){
            updateState(state => ({...state,activeStep: activeStep+1}));
        }else {
            let selectedRange = getDateRangeIncludingEndDate(period,dateRange);
            finishDownloadSetup({name,
                timeInterval:groupInterval,
                separator:decimalSeparator,
                pollutants:pollutantUnits,
                temperature:tempUnits,
                humidity: humidityUnits,
                index:index,
                useInvalidFilter:includeValidation === 1,
                windSpeed:winSpeedUnit,
                stations:selectedStations.map(item => item.id),
                variables:selectedVariables.map(item=>item.id),
                time: localToUTCDateString(selectedRange[0]),
                endtime: localToUTCDateString(selectedRange[1])
            });
        }
    };


    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <Card className={classes.card} data-testid={"create-download-popup"} >
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center" >
                    <Grid item xs={12}>
                        <Typography variant={"h6"} className={classes.tittle}>{t("downloadScreen.popUp.configure_download")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid item xs={12} className={classes.steps}>
                         <DownloadSteps activeStep={activeStep}/>
                    </Grid>
                    {activeStep === 0 && <Grid container item xs={12} className={classes.step1Container} >
                        <Step1
                            decimalSeparator={decimalSeparator} decimalSeparatorCallback={decimalSeparatorCallback}
                            groupInterval={groupInterval} groupIntervalCallback={groupIntervalCallback}
                            windSpeedUnit={winSpeedUnit} windSpeedCallback={windSpeedCallback}
                            pollutantUnit={pollutantUnits} pollutantCallback={pollutantCallback} tempUnit={tempUnits}
                            tempCallback={tempCallback} humidityUnit={humidityUnits} humidityCallback={humidityCallback}
                            index={index} indexCallback={indexCallback} period={period} name={name}
                            nameCallback={nameCallback} initialDate={dateRange} updateDate={updateDateCallback}
                            includeValidation={includeValidation} includeValidationCallback={includeValidationCallback}
                        />
                    </Grid>}
                    {activeStep === 1 && <Grid container item xs={12} className={classes.step2Container} >
                        <Step2 initialSelection={selectedStations} callback={selectedStationsCallback} timeZoneError = {timeZoneError} />
                        {timeZoneError && <Grid item xs={12}>
                            <Typography variant={"body2"}
                                        color={"error"}>{t("downloadScreen.popUp.timeZoneError")}</Typography>
                        </Grid>}
                    </Grid>}
                    {activeStep === 2 && <Grid container item xs={12} className={classes.step2Container} >
                        <Step3 selectedStations={selectedStations} initialSelection={selectedVariables}
                               humidityUnit={humidityUnits} index={index} callback={selectedVariablesCallback} />
                    </Grid>}
                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            {activeStep !== 0 && <Button onClick={cancelDownload}>{t("cancel")}</Button>}
                            <Button onClick={handleFirstButton}>{activeStep === 0 ? t("cancel") : t("back")}</Button>
                            <Button
                                data-testid={"download-config-next"}
                                disabled={activeStep === 0 ? !step1Valid : activeStep === 1 ? !step2Valid : !step3Valid  }
                                onClick={handleSecondButton}>{activeStep <=1 ? t("next") : t("finish")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default DownloadConfigPopUp;
