import React from 'react';
import {getVariableText} from "../../routes/private/pollutantNames";
import {useTranslation} from "react-i18next";
import ToggleButtons from "../ToggleButtons";
import SelectControl from "../SelectControl";
import {useSelector} from "react-redux";

const PollutantSelect = ({index, name, formControlClass, type, disabled, pollutantList, selectedPollutant,
                             onPollutantSelect, fullWidth}) => {

    const {t} = useTranslation();
    const {units} = useSelector(state => state.auth);

    const options = pollutantList?.map(value => ({value, label: getVariableText(value, units)}));

    const handleSelectorChange = (event) => {
        const pollutant = event.target.value;
        onPollutantSelect(pollutant);
    };

    return type === "tabs" ? (
        pollutantList ? (
            <ToggleButtons className={formControlClass}
                           options={options}
                           onChange={handleSelectorChange}
                           value={selectedPollutant} />
        ) : null
    ) : (
        <SelectControl
            className={formControlClass}
            label={name ? t(name) : `${t("chart.pollutant")}${index ? ` ${index}` : ''}`}
            disabled={disabled}
            options={options}
            value={selectedPollutant}
            onChange={handleSelectorChange}
            variant="standard"
            margin="dense"
            testId={index ? `pollutant-select-${index}` : 'pollutant-select'}
            fullWidth={fullWidth}
        />
    );
};

export default PollutantSelect;
