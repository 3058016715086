import React, {useMemo} from 'react';
import MultipleSelector from "../../../../common/multipleSelector/MultipleSelector";
import {getAvailableSensors} from "../../../../../utils/stationUtil";
import {useTranslation} from "react-i18next";
import {sensor} from "../../../../../constants";
import {getAqiIndexNameAndRegionText} from "../../../../../utils/aqiUtil";

const Step3 = ({selectedStations, initialSelection, callback, humidityUnit, index}) => {
    const {t} = useTranslation();

    const availableSensorsOptions = useMemo(() => {
        const units = {humidity: humidityUnit};
        const availableSensors = getAvailableSensors(selectedStations, units).map(item => (
            {id: item.id, label: item.alias}
        ));
        return [
            ...availableSensors,
            {id: sensor.aqi, label: getAqiIndexNameAndRegionText(index, t)}
        ];
    }, [selectedStations, humidityUnit, index, t]);

    return (
        <MultipleSelector initialSelected={initialSelection}
                          items={availableSensorsOptions}
                          selectedCallback={callback}
                          min={1} />
    );
};

export default Step3;
