import React, {useMemo} from 'react';
import MultipleSelector from "../../../../common/multipleSelector/MultipleSelector";
import {getAvailableSensors} from "../../../../../utils/stationUtil";
import {useTranslation} from "react-i18next";
import {sensor} from "../../../../../constants";

const VariableStep = ({selectedStations, initialSelection, humidityType, callback}) => {

    const {t} = useTranslation();

    const availableSensorsOptions = useMemo(() => {
        const aqiItem = {id: sensor.aqi, label: t("legend.AQI")};
        const units = {humidity: humidityType};
        return [...getAvailableSensors(selectedStations, units).map(item => ({id: item.id, label: item.alias})), aqiItem];
    }, [t, selectedStations, humidityType]);

    return (
        <MultipleSelector initialSelected={initialSelection}
                          items={availableSensorsOptions}
                          selectedCallback={callback}
                          min={1} />
    );
};

export default VariableStep;
