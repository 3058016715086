import './chart_style.css';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector, shallowEqual} from "react-redux";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useTranslation} from "react-i18next";
import {unitsMap} from "../../unitsNames";
import {getHourDayOfWeekChartLayout, getHourDayOfWeekData} from "./week/weekChart";
import {getHourlyData, getHourlyChartLayout} from "./hour/hourChart";
import {getDayOfWeekChartData, getDayOfWeekChartLayout} from "./dayOfWeek/dayOfWeekChart";
import {getYearChartLayout, getYearData} from "./year/yearChart";
import CalendarMonthSelector from "../calendar_card/CalendarMonthSelector";
import CardLayout from "../../../../common/card_views/CardLayout";
import ChartLayout from "../../../../common/chart/ChartLayout";
import ChartView from "../../../../common/chart/ChartView";
import {pollutantTrendRequest} from "../../../../../requests/analytics/pollutantTrendRequest";
import useRequest from "../../../../../hooks/useRequest";
import useMessage from "../../../../../hooks/useMessage";
import {abbreviatedWeekDaysTranslation, monthOfYeasTranslation, weekDayTranslation} from "./viewTextTranslateKeys";
import {sensorOptions, plotConfig} from "./tempChartConfig";
import {dayOfWeekAdapter} from "./dayOfWeekUtil";
import {getStationSensors} from "../../../../../utils/stationUtil";


const TemporalVariationCardView = ({className}) => {

    const {t} = useTranslation();
    const notFoundMessage = useMemo(() => {
        return t("dataNotFound");
    }, [t]);
    const HOURLY_CHART_TITLE = t("analyticScreen.temporal_variation.hourly_evolution");
    const YEAR_CHART_TITLE = t("analyticScreen.temporal_variation.monthly_evolution");
    const DAY_OF_WEEK_CHART_TITLE = t("analyticScreen.temporal_variation.evolution_by_day_of_the_week");

    const {setError, setInfo} = useMessage();
    const {handleErrorResponse} = useRequest();
    const isMounted = useMountComponent();
    const {units} = useSelector(state => state.auth);
    const selectedStation = useSelector(state => state.dashboardUI.selectedStation);
    const pollutantList = useSelector(state => {
        const selectedStation = state.dashboardUI.selectedStation;
        const stations = state.dashboardUI.stations;
        const station = stations.find(item => item.id === selectedStation);
        return getStationSensors(station).filter(item => sensorOptions.includes(item));
    }, shallowEqual);

    const [loading, updateLoading] = useState(false);
    const [{
        selectedDate,
        chartData,
        error,
        chartLayout,
        selectedPollutant,
        dataTimeZone
    }, updateState] = useState({
        selectedDate: new Date(),
        chartData: null,
        error: notFoundMessage,
        selectedPollutant: pollutantList[0],
        chartLayout: null,
        dataTimeZone:null
    });

    const {weekDays, abbreviatedWeekDays, monthOfYeas} = useMemo(() => {
        return {
            weekDays: weekDayTranslation.map(item => t(item)),
            abbreviatedWeekDays: abbreviatedWeekDaysTranslation.map(item => t(item)),
            monthOfYeas: monthOfYeasTranslation.map(item => t(item))
        };
    }, [t]);

    const updateChartData = useCallback((pollutant, pollutantData) => {

        const startOnSunday = false;

        if (isMounted.current) {
            const variable = Object.keys(pollutantData)[0];
            const data = pollutantData[variable];
            const dataTimeZone = pollutantData["timeZone"];
            const unit = unitsMap.get(data.units);
            const hourDayOfWeek = dayOfWeekAdapter(data.hourDayOfWeek,"day" ,startOnSunday);
            const hourData = data.hourOfDay;
            const yearData = data.yearData;
            const dayOfWeekData = dayOfWeekAdapter(data.dayOfWeek,"x", startOnSunday);

            updateState(state => (
                {
                    ...state,
                    dataTimeZone,
                    chartLayout: {
                        barmode: 'stack',
                        autosize: true,
                        showlegend: false,
                        hovermode: "x",
                        ...getHourDayOfWeekChartLayout(startOnSunday, weekDays, unit, hourDayOfWeek),
                        ...getHourlyChartLayout(unit, HOURLY_CHART_TITLE),
                        ...getYearChartLayout(monthOfYeas, unit, YEAR_CHART_TITLE, yearData),
                        ...getDayOfWeekChartLayout(abbreviatedWeekDays, startOnSunday, unit, DAY_OF_WEEK_CHART_TITLE, dayOfWeekData),
                    },
                    chartData: [
                        ...getHourDayOfWeekData(pollutant, unit, units, hourDayOfWeek),
                        ...getHourlyData(pollutant, unit, units, hourData),
                        ...getYearData(pollutant, unit, units, yearData),
                        ...getDayOfWeekChartData(pollutant, unit, units, dayOfWeekData)
                    ]
                }));
        }
    }, [DAY_OF_WEEK_CHART_TITLE, HOURLY_CHART_TITLE, YEAR_CHART_TITLE, abbreviatedWeekDays, isMounted, monthOfYeas,
        weekDays, units]);

    useEffect(() => {
        // If the selected sensor is not available, we will set the first available sensor as the selected sensor
        updateState(state => ({
            ...state,
            selectedPollutant: pollutantList.includes(state.selectedPollutant)
                ? state.selectedPollutant
                : pollutantList[0]
        }));
    }, [pollutantList]);

    useEffect(() => {
        if (!pollutantList.includes(selectedPollutant)) return;

        async function fetchData() {
            updateLoading(true);
            try {
                const params = {
                    stationId: selectedStation,
                    pollutants: units.pollutants,
                    temperature: units.temperature,
                    humidity: units.humidity,
                    time: new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 1, 0, 0, 0)).toISOString(),
                    sensor: selectedPollutant
                };

                const data = await pollutantTrendRequest(params);
                if (!data) {
                    updateState(state => {
                        return {...state, chartData: null, error: notFoundMessage};
                    });
                } else
                    updateChartData(selectedPollutant, data);

            } catch (e) {
                handleErrorResponse(e, response => {
                    updateState(state => {
                        return {...state, chartData: null, error: t("analyticScreen.temporal_variation.could_not_update_station_data")};
                    });
                    setError(response, false, "analyticScreen.temporal_variation.could_not_update_station_data");
                });
            } finally {
                updateLoading(false);
            }
        }

        fetchData();
    }, [pollutantList, selectedPollutant, selectedDate, selectedStation, units, handleErrorResponse, setError, setInfo,
        t, updateChartData, notFoundMessage]);

    const pollutantCallback = useCallback((pollutant) => {
        updateState(state => ({...state, selectedPollutant: pollutant}));
    }, []);

    const handleDateChange = useCallback((newDate) => {
        updateState(state => ({...state, selectedDate: newDate}));
    }, []);

    return (
        <CardLayout className={className} title={t("analyticScreen.temporal_variation.title")}
                    helpText={t("analyticScreen.temporal_variation.en_analytics_temp_variation")}>

            <ChartLayout loading={loading}
                         dataTimeZone = {dataTimeZone}
                         emptyData={!chartData}
                         height={780}
                         error={error}
                         pollutantList={pollutantList}
                         onPollutantSelect={pollutantCallback}
                         selectedPollutant={selectedPollutant}
                         chartStyles={{"& .modebar": {left: "50%"}}}
                         position={"top"}
                         sideContent={
                             <CalendarMonthSelector
                                 date={selectedDate}
                                 handleDateChange={handleDateChange}
                                 disable={loading}/>
                         }
            >
                <ChartView
                    layout={chartLayout}
                    data={chartData}
                    config={plotConfig}
                />
            </ChartLayout>
        </CardLayout>
    );
};

export default TemporalVariationCardView;
