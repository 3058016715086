import React from 'react';
import {Provider} from 'react-redux';
import {store} from "./reducers/store/store";
import {TopLevelRoutesTree} from "./components/routes/TopLevelRoutesTree";
import {SnackbarProvider} from "notistack";
import * as Sentry from "@sentry/react";

import './translate/i18n';
import {DialogMessageProvider} from "./hooks/useMessageDialog";

const BettairApp = () => {

    return (

        <Provider store={store}>
            <SnackbarProvider maxSnack={8}>
                <Sentry.ErrorBoundary showDialog>
                    <DialogMessageProvider>
                        <TopLevelRoutesTree/>
                    </DialogMessageProvider>
                </Sentry.ErrorBoundary>
            </SnackbarProvider>
        </Provider>

    );
};

export default BettairApp;
