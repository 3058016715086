import React, {useCallback, useEffect, useState} from 'react';
import {Card, Grid, Stack, TextField, Typography} from "@mui/material";
import RdThresholdTable from "./RdThresholdTable";
import {rdThresholdRequest} from "../../../../../requests/analytics/rdThresholdRequest";
import {useSelector} from "react-redux";
import useRequest from "../../../../../hooks/useRequest";
import HelpPopup from "../../../../common/HelpPopup";
import CardTitle from "../../../../common/card_views/CardTitle";
import {useMountComponent} from "../../../../../hooks/useMountComponent";
import {useAnchorEl} from "../../../../../hooks/useAnchorEl";
import CardLoading from "../../../../common/card_views/CardLoading";
import {useTranslation} from "react-i18next";
import useMessage from "../../../../../hooks/useMessage";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {getDateFnsLocale} from "../../../../../utils/dateUtil";
import DataNotFound from "../../../../common/DataNotFound";

const RdThresholdCardView = ({className}) => {

    const {anchorEl,setAnchorEl,handleHelpClose} = useAnchorEl();

    const {t} = useTranslation();
    const {handleErrorResponse} = useRequest();
    const { setError } = useMessage();
    const [{data, loading}, updateState] = useState({data: null, loading: true});
    const selectedStation = useSelector(state => state.dashboardUI.selectedStation);
    const {units, legalThreshold} = useSelector(state => state.auth);
    const [date, setDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [dateOpen, setDateOpen] = useState(false);
    const [noLocationError, setNoLocationError] = useState(null);

    const isMounted = useMountComponent();

    const fetchRdThresholdData = useCallback(()=>{
        if (!selectedStation) return;
        setNoLocationError(null);
        updateState({data: null, loading: true});
        const year = date;
        if (year) {
            rdThresholdRequest(units.pollutants, selectedStation, year).then((data) => {
                if (!isMounted.current) return;

                if(!data)
                    setNoLocationError("requestErrors.noPositionFound");

                updateState(state =>{
                    return {...state, data, loading: false};
                });
            }).catch((err) => {
                if (!isMounted.current) return;
                const {request} = err;
                updateState(state => ({
                    ...state,
                    data: null,
                    loading: false
                }));
                handleErrorResponse(request, response => {
                    setError(response, false, "analyticScreen.rdThreshold.could_not_update_rd_table");
                });
            });
        }
    },[handleErrorResponse, setError, isMounted, selectedStation, units.pollutants, date]);

    useEffect(() => {
        fetchRdThresholdData();
    }, [fetchRdThresholdData]);

    return (
        <Card className={className}>
            { !loading &&
                <Grid container direction={"row"}>
                    <Grid container item xs={12} xl={9} alignItems={"center"} alignContent={"center"}>
                        <CardTitle title={t("analyticScreen.rdThreshold.title")} setAnchorEl={setAnchorEl}/>
                    </Grid>
                    <Grid item xs={12} xl={3}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: "100%"
                        }}>
                            <Typography variant={"subtitle2"} style={{marginRight: 15}}>
                                {t("units.year")}
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getDateFnsLocale()}>
                                <Stack spacing={3}>
                                    <DatePicker
                                        views={['year']}
                                        //label="Year"
                                        value={date}
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                        }}
                                        minDate={new Date(2000, 0, 1)}
                                        maxDate={new Date()}
                                        open={dateOpen}
                                        onOpen={() => setDateOpen(true)}
                                        onClose={() => setDateOpen(false)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onClick={() => setDateOpen(true)}
                                                helperText={null}
                                                size="small"
                                                style={{width: 68}}
                                                InputProps={{
                                                    endAdornment: null,
                                                }}
                                            />
                                        )}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </div>
                    </Grid>
                    {/* Add vertical margin on large screens */}
                    <Grid item xs={0} xl={12} style={{marginTop: 25}}/>
                    {data ? (
                        <Grid item xs={12} style={{minHeight: 320}}>
                            {(data.lowEmissionZones || data.notRestrictedZones) ? (
                                <RdThresholdTable rdData={data}/>
                            ) : (
                                <DataNotFound/>
                            )}
                        </Grid>
                    ) : (
                        <DataNotFound message={t( noLocationError ?? 'chart.errorFetchingData')}/>
                    )}
                </Grid>
            }
            {loading && <CardLoading/>}
            <HelpPopup anchorEl={anchorEl} handleHelpClose={handleHelpClose}
                       message={t(`analyticScreen.rdThreshold.help_text_${legalThreshold.toLowerCase()}`)}
                       halfCard={true}/>
        </Card>
    );
};

export default RdThresholdCardView;
